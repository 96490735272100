.common-yados-carousel {
  padding: 30px 12px;
  width: 100%;

  .common-yados-container {
    position: relative;
    min-width: 357px;
    width: 100%;
    margin: 0 auto;

    &.with-border {
      .card-wrapper {
        border-radius: 3px;
        border: 1px solid #e8e8e8;
      }
    }

    h2 {
      padding-bottom: 8px;
      font-size: 1.5rem;
    }

    .carousel-container {
      position: unset;

      .react-multiple-carousel__arrow--left {
        left: -22px;
      }

      .react-multiple-carousel__arrow--right {
        right: -22px;
      }

      .card-wrapper {
        border-radius: 3px;
        width: 158px;
        height: 277px;

        .card-content-wrapper {
          padding: 7px 10px 11px;

          .yado-name {
            min-height: 45px;
          }
        }
      }
    }
  }
}

@include mobile {
  .common-yados-carousel {
    padding: 40px 30px;
    height: 435px;

    &.height-auto {
      height: auto;
    }

    .common-yados-container {
      max-width: 100%;

      h2 {
        padding-bottom: 15px;
      }

      .carousel-container {
        .card-wrapper {
          width: 208px;
          height: 299px;

          .card-content-wrapper {
            .yado-name {
              min-height: 36px;
            }
          }
        }
      }
    }
  }
}

@include ipad {
  .common-yados-carousel {
    .common-yados-container {
      max-width: 720px;
    }
  }
}

@include tablet {
  .common-yados-carousel {
    .common-yados-container {
      max-width: 960px;
    }
  }
}

@include desktop {
  .common-yados-carousel {
    .common-yados-container {
      max-width: 1163px;
    }
  }
}
