@import '../breakpoints.scss';

.gray-background {
  width: 100%;
  background-color: #f7f7f7;
}
.top-title {
  text-align: left;
  margin-bottom: 30px;
  font-size: 1.5rem;
  line-height: 34.75px;
}

.image-container {
  position: relative;

  .title {
    text-align: center;
    width: 70%;
    font-size: 1.1875rem;
    color: white;
    font-weight: 700;
    line-height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .brightness {
    filter: brightness(0.7);
  }
}

.card-image-container {
  min-width: fit-content;
  height: fit-content;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}

.carousel-container {
  .card-wrapper {
    background-color: white;

    .card-content-wrapper {
      display: flex;
      flex-direction: column;

      .name {
        min-height: 53px;

        .area-name {
          min-height: 17px;
        }
      }

      .price-content {
        min-height: 65px;
        text-align: right;
        &.animated {
          transition: opacity 0.3s linear 0.1s;
          will-change: opacity;
          opacity: 0;
        }

        &.animated.loaded {
          opacity: 1;
        }
      }

      .price-comp {
        display: flex;
        flex-direction: column;
        margin-bottom: 3px;
        gap: 3px;
      }

      .yado-name {
        line-height: 16px;
        word-break: break-all;
      }

      .price {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
      }

      .currency {
        font-size: 12px;
      }

      .per-person {
        color: #333333;
        font-size: 0.75rem;
        line-height: 15.6px;

        p {
          margin: 0;
        }
      }

      .services {
        border-top: 1px solid #e8e8e8;

        .facility-label-container,
        .access-label-container {
          border-radius: 20px;
        }
      }
    }
  }

  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    &:focus {
      outline: none;
    }
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
    text-decoration: none;
  }
}

.services {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-top: 8px;
  width: 100%;

  .facility-label-container {
    margin: 0px 8px 5px 0px;
  }
}

.tabs {
  display: flex;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;

  .tab {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    border: 1px solid #9b9b9b;
    border-bottom: none;
    margin: 0 2.5px;

    &.active_item {
      background-color: lighten(#2187f2, 43);
      color: darken(#2187f2, 10);

      span {
        color: darken(#2187f2, 10);
      }
    }

    &:first-child {
      margin-left: 9px;
    }

    &:last-child {
      margin-right: 9px;
    }

    &:hover {
      cursor: pointer;
    }

    span {
      color: #545454;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}

@include desktop {
  .top-title {
    margin-bottom: 35px;
  }
}

.font-sans-serif {
  font-family: sans-serif !important;
}
