.photo-description-container {
  font-size: 14px;

  .photo-room {
    display: inline-flex;
    flex-wrap: wrap;
    .photo-room-facilities {
      &:last-child {
        .slash {
          display: none;
        }
      }

      .label {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.93;
        letter-spacing: 1.03px;
        color: $dark-gray-text-color;
      }
      .slash {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.93;
        color: $form-input-gray-border;
        margin: auto 7px;
      }
    }
  }

  .photo-bath-amenities {
    column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 20px;
    border-bottom: 1px solid $onsen-gray-border;
  }

  .photo-bath-amenity-note {
    margin-top: 20px;
    white-space: pre-line;
  }
}

@include max-screen($breakpoint-sm-max) {
  .photo-description-container {
    .photo-bath-amenities {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
