@import '../../variables.scss';

.filter-condition-label {
  border: solid 1px $pjt-blue;
  border-radius: 20px;
  padding: 5px 10px;
  color: $pjt-blue;
  background-color: #ffffff;
  font-size: $pjt-font-xxs;
  font-weight: bold;
  letter-spacing: 0.63px;
  margin-right: 5px;
  white-space: nowrap;
  position: relative;
  padding-right: 30px;
  font-weight: 400;

  &.price-range {
    padding-right: 10px;
  }

  .filter-summary-text {
    font-size: 10px;
  }

  button {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    color: #2187f2;
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 30px;
  }
}

@include min-screen($breakpoint-sm-max) {
  .filter-condition-label {
    margin-bottom: 5px;
  }
}
