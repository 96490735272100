.prefecture-by-region {
  max-width: 1163px;
  margin: 0 auto 100px;

  .prefecture-by-region-title {
    @include pjt_h3;
    margin-bottom: 30px;
  }

  .region-tabs-wrapper {
    display: flex;
    justify-content: space-around;
  }
  
  .region-tab {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-bottom: solid 1px $pjt-border;
  
    &:hover {
      cursor: pointer;
      background-color: $pjt-background-bluish;
    }
  
    &--active {
      border-bottom: solid 2px $pjt-blue;
    }
  }

  .region-select {
    display: none;
  }
  
  .region-prefectures {
    display: flex;
    margin-top: 25px;
    gap: 15px;
  
    .prefecture {
      padding: 10px;
      height: 43px;
      border: solid 1px $pjt-border;
      transition: border 0.3s;

      &:hover {
        border: solid 1px $pjt-blue;
      }
    }
  
    .prefecture-link {
      color: black;
      text-decoration: none;
  
      &:hover {
        text-decoration: none;
        color: black;
      }
    }
  }

}

@media screen and (max-width: $breakpoint-md-max) {
.prefecture-by-region {
  width: 100%;
  padding: 0 12px;
  margin: 0 auto 50px;


  .region-tabs-wrapper {
    display: none;
  }

  .region-select {
    position: relative;
    display: block;
    border: solid 1px $pjt-border;
    border-radius: 3px;
    width: fit-content;
    height: 45px;
    padding: 10px 35px 10px 10px;

    .region-select-arrow {
      position: absolute;
      top: 45%;
      right: 5px;
    }
  }

  .region-prefectures {
    display: block;

    .prefecture {
      display: block;
      border: none;
      border-bottom: solid 1px $pjt-border;
    }
  }
}
}

