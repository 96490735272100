.aftee-modal-content {
  max-width: 910px;
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  outline: none;
  padding: 0px;
  margin: auto;

  a {
    color: $pjt-blue;
    text-decoration: underline;
  }

  .close-button {
    margin: 15px 5px;
    cursor: pointer;
    max-width: 20px;
    margin-left: auto;
  }

  .about-aftee-modal {
    border-radius: 3px;
    height: 92%;
    max-width: 100%;
    overflow: auto;
    background-color: #fff;
    padding: 30px;
    font-size: 14px;
    color: #1e2127;

    h2 {
      padding-bottom: 15px;
      border-bottom: 1px solid #ddd;
    }

    .aftee-description {
      margin: 20px 0;
    }

    ul {
      padding-left: 20px;
      list-style-type: decimal;

      li {
        &::marker {
          min-width: 17px;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  &.aftee-modal-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5;
    opacity: 0;
    -webkit-transition: opacity 100ms linear;
    -moz-transition: opacity 100ms linear;
    -o-transition: opacity 100ms linear;
    transition: opacity 100ms linear;
  }
}

.ReactModal__Overlay--after-open {
  &.aftee-modal-overlay {
    opacity: 1;
  }
}

.ReactModal__Overlay--before-close {
  &.aftee-modal-overlay {
    opacity: 0;
  }
}

@include mobile {
  .aftee-modal-content {
    position: absolute;
    top: 12%;

    .about-aftee-modal {
      height: 87%;
    }
  }
}
