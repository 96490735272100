@import '../variables.scss';

.review-button {
  padding: 0 13px;
  background-color: #ffffff;
  border: 1px solid #dce5ee;
  border-radius: 1px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  padding: 10px 15px;

  &:focus {
    outline: 0;
  }

  &.isSelected {
    background-color: #eaf1f8;
  }

  &:disabled {
    background-color: #e8e8e8;
    color: #545454;
  }
}

.review-score {
  font-size: 16px;
}

.count {
  font-size: 13px;
  color: #6b6b6b;
}
