@import './breakpoints.scss';
.questionnaire-toast {
  position: fixed;
  right: auto;
  left: 50%;
  bottom: -0%;
  transform: translate(-50%, 100px);
  opacity: 0;
  transition: 1s all ease;
  padding: 20px 16.5px 15px;
  width: 313px;
  border: 3px solid #e8e8e8;
  text-align: center;
  background-color: #ffffff;
  z-index: 10000;

  &.active {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  .close-button {
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    right: -16.5px;
    top: -16.5px;
    z-index: 3;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 700;
  }

  span {
    font-size: 0.8125rem;
    line-height: 1.3;
    font-weight: 700;
  }

  button {
    width: 100%;
    height: 46px;
    background-color: #2187f2;
    font-weight: 700;
    color: #ffffff;
    border: none;
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
  }
}

.questionnaire-top-button {
  background-color: #ffffff;
  letter-spacing: 0.01rem;
  font-size: 0.9375rem;
  line-height: 1.7;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  color: #2187f2;
  border: 3px solid #2187f2;
  cursor: pointer;
  display: flex;
  padding: 10px 20px;
  width: 100%;
  height: 46px;
  line-height: 16px;

  p {
    margin: 0;
  }

  .icon {
    height: 15px;
    width: 10px;
  }
}

.questionnaire-section-button {
  letter-spacing: 0.01rem;
  font-size: 0.9375rem;
  line-height: 1.7;
  font-weight: 700;
  color: #2187f2;
  max-width: 405px;
  width: calc(100% - 30px);
  background-color: #eaf1f8;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  .icon-container {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-container {
    width: calc(100% - 65px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      padding-left: 10px;
      margin: 0;
    }
  }

  
}

@include mobile {
  .questionnaire-toast {
    position: fixed;
    left: auto;
    right: 25px;
    bottom: -100px;
    transform: translate(0, 0);

    &.active {
      right: 25px;
      bottom: 20px;
      transform: translate(0, 0);
    }
  }
}
