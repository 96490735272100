.suggestion-regions {
  padding: 0;
  max-width: 100%;
  background-color: $onsen-white;

  .region-title {
    background-color: $onsen-gray-200;
    width: 100%;
    padding: 14px 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    letter-spacing: 1.03px;
    color: $dark-gray-text-color;
  }

  .region-container {
    display: flex;
    flex-direction: column;

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
    }

    .title {
      color: $dark-gray-text-color;
      border-bottom: 1px solid $onsen-gray-300;
      border-right: none;
      padding: 10px 20px;
      text-align: start;

      &:hover {
        background-color: $onsen-gray-300;
      }

      &.kyushu {
        border-bottom: none;
      }
    }
  }
}

@include mobile {
  .suggestion-regions {
    .region-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'item1 item2 item3 item4'
        'item5 item6 item7 item8';

      .title {
        color: #333333;
        border-bottom: 1px solid $onsen-gray-300;
        border-right: 1px solid $onsen-gray-300;
        padding: 10px 0;
        text-align: center;

        &.kansai,
        &.chugoku,
        &.shikoku,
        &.kyushu {
          border-bottom: none;
        }

        &.chubu,
        &.kyushu {
          border-right: none;
        }
      }
    }
  }
}
