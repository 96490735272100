.photo-gateway-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px 5px;
  grid-template-areas:
    'item1 item2 item3 item4'
    'item1 item5 item6 item7';

  .photo-gateway-wrapper {
    width: fit-content;
    position: relative;
    display: block;
    padding: 0;
    appearance: none;
    border: none;
    background: none;
    outline: none;
    pointer-events: none;

    &:nth-child(1) {
      grid-area: item1;
    }

    &:last-child {
      .next-image-container {
        pointer-events: painted;
        filter: brightness(0.5);
      }

      .amount {
        display: block;
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translateX(-50%) translateY(-50%);
        color: $onsen-white;
        text-align: center;

        .image-icon {
          width: 28px;
          height: 28px;
        }

        p {
          margin-top: 10px;
          text-wrap: nowrap;
          font-size: 12px;
        }
      }
    }

    .amount {
      display: none;
    }
  }
}

.photo-gateway-mobile {
  position: relative;

  .image-gallery-image {
    height: 282px;
  }

  .gateway-button-mobile {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    right: 15px;
    bottom: 18px;
    outline: 0;
    border: 0;

    .amount {
      color: $onsen-white;
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: normal;
      padding: 4px 5px;
      letter-spacing: 0.4px;

      .image-icon {
        width: 13px;
        height: 13px;
        margin-right: 7px;
        margin-bottom: 1px;
      }
    }
  }
}

@include max-screen($photo-category-lg-max) {
  .photo-gateway-container {
    width: min-content;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px 5px;
    grid-template-areas:
      'item1 item2 item3'
      'item1 item4 item5';
  }
}

@include max-screen($photo-category-md-max) {
  .photo-gateway-container {
    width: min-content;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px 5px;
    grid-template-areas:
      'item1 item2'
      'item1 item3';
  }
}
