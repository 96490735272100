@import '../../variables.scss';

.filter-condition-wrapper {
  background: #ffffff;
  margin-bottom: 0;
  border-bottom: 1px solid #e8e8e8;

  .filter-condition-title {
    padding: 10px 10px 0;
    position: relative;
    background: #efefef;
    width: 100%;
    font-size: $pjt-font-m;
    font-weight: 500;
    letter-spacing: 1.03px;
    line-height: 40px;

    &.limited-plan {
      color: $onsen-white;
      background-color: $pjt-limited-yellow;
    }
  }

  .filter-condition-clear {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
    color: #1987d8;
    cursor: pointer;
  }

  .filter-condition-box {
    padding: 20px 15px 30px;

    &.flex-gap {
      flex-wrap: wrap;
      gap: 3px 10px;

      label {
        width:calc(50% - 5px);
      }
    }
  }
}

.toggle-button {
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: $pjt-blue;
  font-size: $pjt-font-xxs;
  justify-content: center;
  margin: 10px 0;
  padding-bottom: 10px;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  img {
    margin-left: 5px;
    font-family: '微軟正黑體', 'Microsoft JhengHei', 'Microsoft JhengHei UI', '微軟雅黑',
      'Microsoft YaHei', 'Microsoft YaHei UI', sans-serif;
  }
}

@include min-screen($breakpoint-sm-min) {
  .filter-condition-wrapper {
    margin-bottom: 10px;
    border-bottom: none;

    .filter-condition-title {
      background: #ffffff;
      font-weight: bold;

      &::after {
        content: '';
        height: 1px;
        width: 90%;
        background: #e8e8e8;
        position: absolute;
        left: 10px;
        transform: translateY(40px);
      }
    }

    .filter-condition-box {
      padding: 10px;

      &.flex-gap {
        flex-wrap: nowrap;
        gap: 0 9px;
  
        label {
          margin-bottom: 0;
        }
      }
    }
  }
}
