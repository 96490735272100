@import '../../variables.scss';

.map-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: $onsen-gray-100;
}

.nearbys-title {
  color: $dark-gray-text-color;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 2px solid $onsen-gray-300;
}

.modal-open-btn-chevron {
  margin-left: 7px;
}
