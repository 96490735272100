.intl-tel-input {
  width: 100%;

  input {
    padding-left: 108px !important;
    border: 1px solid $pjt-border;
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
    line-height: normal;
    width: 100%;
  }

  input::placeholder {
    font-size: $pjt-font-m;
    font-weight: 400;
    color: $pjt-gray;
    font-style: initial;
  }

  input[type='tel'] {
    margin-top: 5px !important;
  }

  &.allow-dropdown {
    &.separate-dial-code {
      .selected-dial-code {
        position: absolute;
        left: 33%;
        top: 24%;
        padding: 0;
        pointer-events: none;
      }
    }
  }

  .flag-container {
    height: 43px;
  }
  .selected-flag {
    width: 100px !important;
    margin-top: 5px !important;
    border-radius: 5px 0 0 5px;
  }

  .selected-dial-code {
    font-weight: normal;
  }
}
