@import 'variables';
@import 'en-overrides';

html,
body,
#__next {
  height: fit-content;
}

:lang(en) {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
}

:lang(zh-cmn-Hant-TW),
:lang(zh-cmn-Hant-HK) {
  font-family: '微軟正黑體', 'Microsoft JhengHei', 'Microsoft JhengHei UI', '微軟雅黑',
    'Microsoft YaHei', 'Microsoft YaHei UI', sans-serif;
}

:lang(zh-cmn-Hans-CN) {
  font-family: microsoft yahei, pingfang SC, heiti, sans-serif;
}

#__next {
  position: relative;
}

ul {
  padding-left: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-center {
  margin: 0 auto;
}

.global-paper {
  padding: 1.5rem;
  box-shadow: 0px 0px 4px 0px rgba(124, 202, 245, 0.35);
  background-color: $onsen-white;
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.8px;
    color: $black-text-color;
  }
}

.paper-lg-padding {
  padding: 30px 20px;
}

.global-body-text {
  color: $black-text-color;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.6;
  margin: 0;
}

.global-form-label {
  font-size: $pjt-font-m;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.62px;
  color: #2f2f2f;
}

.h1 {
  color: $black-text-color;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01071em;
  margin: 0;
  display: block;
}

.h4 {
  color: $black-text-color;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.00735em;
  margin: 0;
  display: block;
}

.h5 {
  @include pjt_h5;
}

.h6 {
  color: $black-text-color;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;
  display: block;
}

.pjt-h3 {
  @include pjt_h3;
}

.pjt-h4 {
  @include pjt_h4;
}

.common-normal-text {
  font-size: $pjt-font-m;
  font-weight: 400;
  line-height: 185%;
  color: $dark-gray-text-color;
  white-space: break-spaces;

  &__small {
    font-size: $pjt-font-s;
  }

  &__black {
    color: black;
  }
}

.subtitle {
  color: $black-text-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0;
  display: block;
}

.caption {
  color: $gray-text-color;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.375em;
  margin: 0;
  display: block;
}

.paragraph {
  margin-bottom: 16px !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

@mixin btn-base($color: $pjt-blue) {
  background-color: $onsen-white;
  border: 1px solid $color;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  color: $color;
  text-align: center;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  cursor: pointer;
}

.btn-primary {
  @include btn-base($onsen-pink);
  &--no-border {
    border: 0 !important;
    padding: 0;
    margin: 0.5rem 1.5rem;
  }
  &:hover {
    background-color: lighten($onsen-pink, 45);
    color: $onsen-pink;
    text-decoration: none;
  }
}

.btn-secondary {
  @include btn-base($pjt-blue);
  background-color: $pjt-blue;
  border: 2px solid $onsen-white;
  color: $onsen-white;

  &:hover {
    color: $onsen-white;
    text-decoration: none;
  }

  &--no-border {
    background-color: transparent;
    color: $pjt-blue;
    border: 0 !important;
    padding: 0;
    margin: 0.5rem 1.5rem;
    box-shadow: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  &--with-border {
    @include btn-base($pjt-blue);
    background-color: transparent;
    border: 1px solid $pjt-blue;
    color: $pjt-blue;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  a {
    color: $onsen-white;
    text-decoration: none;
  }
}

.rounded-selector-container {
  position: relative;

  label {
    display: block;
  }

  select {
    color: $dark-gray-text-color;
    background-color: $onsen-white;
    border: 1px solid $form-input-gray-border;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    font-size: $pjt-font-m;
    padding-left: 5px;
    height: 38px;
    min-width: 115px;
    max-width: 225px;
  }
}

input:disabled,
select:disabled {
  background: $onsen-background !important;
  border: 1px solid $form-disabled-border;
  color: $pjt-gray;
  cursor: not-allowed;
}

// nprogress
#nprogress .bar {
  background: $onsen-pink;
  z-index: 1051;
}
#nprogress .peg {
  box-shadow: 0 0 8px $onsen-pink, 0 0 4px $onsen-pink;
}
#nprogress .spinner {
  display: none;
}

// react-modal
.ReactModal__Body--open {
  overflow: hidden;
}
