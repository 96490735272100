.search-result-wrapper {
  border: 1px solid $pjt-border;
  position: relative;
  background-color: $onsen-white;
  transition: all ease-in 0.2s;
  display: block;

  &:hover {
    box-shadow: 0 0 8px $gray300;
  }

  .images-container {
    .yado-image-container {
      width: 100%;
      height: 250px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  a {
    width: 100%;
  }

  .link-wrapper {
    background-color: transparent;
    border: none;
    width: 100%;
    color: $dark-gray-text-color;
    text-decoration: none;

    &:focus {
      outline: none;
    }

    .yado-info-container {
      padding: 10px 10px 20px 10px;
      width: auto;
      height: 100%;

      .top-content {
        display: flex;
        width: 100%;
        height: 100%;

        .yado-left-container {
          flex: 1 1 50%;
          width: 100%;

          .region-wrapper {
            width: 0;
            align-items: center;
            display: flex;
            font-size: 0.8125rem;
            font-weight: 400;
            color: $gray400;
            line-height: 18.16px;
            white-space: nowrap;

            span {
              &:nth-child(even) {
                margin: 0 5px;
              }
            }
          }

          .name-wrapper {
            align-items: center;
            display: flex;
            margin-bottom: 12px;
            flex-wrap: wrap;
            text-align: left;

            .name {
              font-size: 1rem;
              font-weight: 700;
            }

            .secondary-name {
              font-size: 0.8125rem;
              font-weight: 700;
              color: $gray400;
            }
          }

          .rate-wrapper {
            font-size: 13px;

            .rate {
              margin-right: 10px;
            }
          }
        }

        .yado-right-container {
          align-self: center;
          flex: 1;
          width: 100%;
          padding-top: 15px;

          .price-wrapper {
            margin: 10px 0;
          }

          .price-info {
            white-space: nowrap;
            line-height: 1em;
            span {
              display: block;
            }
          }
        }
      }

      .services {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        padding-top: 15px;
        border-top: 1px solid $pjt-border;
        width: 100%;
      }

      .catchphrase {
        word-break: break-word;
        margin-top: 10px;
        font-size: 13px;
        color: $dark-gray-text-color;
        letter-spacing: 1px;
        text-align: left;
      }
    }
  }
}

@include ipad {
  .search-result-wrapper {
    display: flex;

    .images-container {
      .yado-image-container {
        width: 300px;
        max-height: 219px;
        height: 100%;
        position: relative;
      }
    }

    .link-wrapper {
      .yado-info-container {
        padding: 16.5px 24px 20px 22px;
        .top-content {
          .yado-right-container {
            padding: 0;
          }
        }
      }
    }

    .yado-info-container {
      padding: 20px;
    }
  }
}
