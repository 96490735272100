@import './breakpoints.scss';
.routine-campaign {
  width: 100%;
  margin: 20px auto 0;
  white-space: pre-line;

  .routine-cpn-banner {
    img {
      width: 100%;
    }
  }

  .routine-cpn-content {
    width: 100%;
    padding: 0 10px;

    h2 {
      padding: 40px 0 5px;
      border-bottom: 2px solid #e8e8e8;
    }

    .routine-cpn-ul {
      line-height: 28px;
      padding-left: 25px;
      list-style-position: outside;

      &.decimal {
        list-style-type: decimal;
      }
    }
  }

  .routine-cpn-btn {
    button {
      width: 320px;
      height: 55px;
      box-shadow: none;
    }

    button:disabled,
    button[disabled] {
      background-color: #9b9b9b;
    }
  }

  .faq {
    font-size: 15px;

    p {
      padding: 10px;
    }
  }
  .faq-question {
    background-color: #eaf1f8;
    font-weight: 700;
    margin: 0;
  }

  .faq-answer {
    margin-bottom: 13px;
  }
}

.announcement-title {
  margin-top: 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #f06083;
}

.announcement-banner {
  img {
    max-width: 380px;
    width: 100%;
  }
}

.announcement {
  white-space: pre-line;
}

@include mobile {
  .routine-campaign {
    margin: 40px auto 0;
    max-width: 700px;

    .routine-cpn-content {
      padding: 0;
    }
  }
}
