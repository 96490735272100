.top-exclusive-yados {
  display: flex;
  flex-direction: column;
  margin: 35px auto 0;
  max-width: 1163px;
  text-align: left;

  .see-more-sp {
    display: none;
  }
}

.exclusive-yados-header {
  display: flex;
  max-width: 1163px;
  justify-content: space-between;
  .see-more {
    display: block;
    padding-top: 10px;
    a {
      color: $pjt-blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $pjt-blue;
      }
    }
  }
}

.exclusive-yados-wrapper {
  display: flex;
  gap: 40px;
}

.exclusive-yado-card {
  position: relative;
  max-width: 360px;
  @include top_hover;

  .exclusive-yado-link {
    color: $onsen-black;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .exclusive-yado-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: $onsen-white40;
    }
  }

  .card-limited-tag {
    width: 104px;
    margin: 10px 0 0 6px;
    padding: 0px;
    background-color: $pjt-limited-yellow;
    border-radius: 2px;
    opacity: 0.95;
    color: $onsen-white;
    font-size: 12px;
    text-align: center;
    padding: 4px 0;
  }

  .card-image-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .card-content-wrapper {
    padding: 10px 6px;
  }
  .name {
    margin-bottom: 12px;
    min-height: 0;
  }
  .yado-name {
    @include pjt_h5;
  }

  .yado-details {
    display: flex;
  }

  .yado-details-left {
    flex: 1;
    justify-content: left;
  }

  .area-name {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .rate {
    font-size: 14px;
    font-weight: 4000;
  }

  .rating {
    font-weight: bold;
    margin-right: 2px;
  }
  .yado-details-right {
    flex: 1;
    justify-content: right;
  }

  .price-comp {
    margin-bottom: 5px;
  }

  .price {
    display: flex;
    margin-top: 2px;
    justify-content: right;
    gap: 5px;
  }

  .price-info {
    font-size: 9px;
  }

  .per-person {
    text-align: right;
    font-size: 12px;
    color: $gray400;
  }

  .exclusive-benefits {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 15px;
    color: $pjt-benefit-text-brown;
    font-weight: 700;
    line-height: 170%;

    background-color: $pjt-benefit-bg-yellow;

    .benefit-list {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      max-height: 100px;
    }
  }

  .benefits-icon {
    margin-right: 5px;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .top-exclusive-yados {
    display: flex;
    flex-direction: column;
    margin: 50px auto 0;
    text-align: left;

    .see-more-sp {
      color: $pjt-blue;
      display: flex;
      height: 50px;
      max-width: fit-content;
      margin: 0 auto;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      border: 1px solid $pjt-blue;

      .see-more-arrow {
        transform: rotate(90deg);
      }
    }

    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .exclusive-yados-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto 5px;
    max-width: 1163px;
    text-align: left;
  }

  .exclusive-yados-header {
    padding: 10px;
    .see-more {
      display: none;
    }
  }
}
