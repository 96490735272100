.about-services {
  background-color: $onsen-white;
  padding-top: 100px;
  min-height: 700px;
}

.services-sp {
  display: none;
}

.services-table {
  display: flex;
  max-width: 1100px;
  margin: 50px auto 120px auto;
}

.services-list-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: auto;
  
}
.services-list-main {
  max-width: 800px;
  flex: 70%;
  border-top: solid 1px $onsen-gray-400;
  border-bottom: solid 1px $onsen-gray-400;
  border-left: solid 1px $onsen-gray-400;
  height: auto;
}
.services-list-sidebar {
  max-width: 300px;
  width: 100%;
}
.widget--sticky {
  position: sticky;
  top: 60px;
  height: auto;
}

.services-list {
  top: 0px;
  max-width: 300px;
  border-bottom: solid 1px $onsen-gray-400;
}

.services-item {
  min-height: 200px;
  border-top: solid 1px $onsen-gray-400;
  border-bottom: solid 1px $onsen-gray-400;
  padding: 40px 30px;
  cursor: pointer;

  &:hover {
    background-color: $onsen-gray-100;
  }

  &:last-child {
    border-bottom: none;
  }
}
.services-item + .services-item {
  border-top: none;
}
.item-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: $onsen-gray-300;
}
.item-text {
  padding-top: 20px;
  text-align: center;
}
.services-qna {
  flex: 70%;
}
@media screen and (max-width: $breakpoint-md-max) {
  .services-sp {
    display: block;
  }

  .services-pc {
    display: none;
  }

  .about-services {
    background-color: $onsen-white;
    padding-top: 48px;
  }
  .services-list {
    max-width: 100%;
    width: 100%;
    border-right: none;
    border-bottom: none;
  }
  .services-item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 0;
    height: 40px;
    border-top: solid 1px $onsen-gray-600;
    border-bottom: solid 1px $onsen-gray-600;
    padding: 0px;
    font-size: 12px;

    &:last-child {
      border-bottom: solid 1px $onsen-gray-600;
    }
  }

  .services-item-arrow {
    color: $onsen-gray-800;
    width: 10px;
    height: 8px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%)
  }

  .item-icon-wrapper {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 10px;
    background-color: $onsen-gray-300;
  }
  .item-icon {
    width: 12px;
    height: 12px;
  }
  .item-text {
    padding: 0 20px 0 0;
    text-align: left;
  }
}

