.support-modal {
  .support-header {
    flex-direction: column;
  }

  .chat-card {
    margin-top: 15px;
    max-width: 320px;
    padding: 20px;

    .describe {
      display: block;
      font-weight: 700;
    }
  }

  .support-intro-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.support-order-form {
  .chat-card {
    margin-top: 25px;
    padding: 20px;
    max-width: 340px;
  }
}

.support-order-form,
.support-intro-wrapper {
  .support-header {
    flex-direction: row;
  }
}

.support-announce {
  display: grid;
  place-items: center;
  margin: 60px 0;
  font-weight: 600;
  padding: 0 20px 70px;

  div {
    max-width: 800px;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .support-order-form {
    .chat-card {
      margin-top: 15px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .support-intro-wrapper {
    .support-header {
      flex-direction: column;
    }
  }

  .support-modal {
    .support-header {
      flex-direction: column;
    }

    .chat-card {
      border-radius: 3px;
      max-width: 320px;
      padding: 0;
      margin-top: 10px;

      .describe {
        display: none;
      }
    }

    .support-intro-content {
      gap: 18px;
    }
  }

  .support-announce {
    margin: 40px 0;
    padding: 0 15px 40px;
  }
}
