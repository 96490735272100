.area-popular-yados {
  position: relative;
  max-width: 1163px;
  margin: 100px auto;
  overflow: visible;

  .area-popular-yados-pc {
    display: block;
  }

  .area-popular-yados-sp {
    display: none;
  }

  .area-popular-header {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
  }

  .region-tabs {
    display: flex;
    gap: 25px;
    padding-top: 10px;
    margin-bottom: 35px;

    .region-tab {
      width: fit-content;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        font-weight: bold;
        text-decoration: underline 2px $pjt-blue;
        text-underline-offset: 5px;
      }

      &--active {
        font-weight: bold;
        text-decoration: underline 2px $pjt-blue;
        text-underline-offset: 5px;
      }
    }
  }
  .carousel-container {
    position: unset;
  }
  .carousel-item {
    display: flex;
  }
  .react-multiple-carousel__arrow {
    background: transparent;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 42px;

    &:hover {
      background-color: transparent;
      opacity: 80%;
    }
  }

  .react-multiple-carousel__arrow::before {
    color: $onsen-gray-800;
    font-size: 42px;
  }

  .react-multiple-carousel__arrow--left {
    left: -60px;
  }

  .react-multiple-carousel__arrow--right {
    right: -40px;
  }

  .react-multi-carousel-arrow:before {
    color: #000000;
    font-size: 20px;
  }
  .rank-number {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}

.area-popular-yado-card {
  position: relative;
  max-width: 260px;
  @include top_hover;

  .card-limited-tag {
    width: 104px;
    margin: 10px 0 0 6px;
    background-color: $pjt-limited-yellow;
    border-radius: 2px;
    opacity: 0.95;
    color: $onsen-white;
    text-align: center;
    font-size: 12px;
    padding: 5px;
  }

  .card-image-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .area-popular-yado-link {
    color: $onsen-black;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .card-content-wrapper {
    padding: 5px 6px;
  }
  .yado-name-wrapper {
    margin: 0 0 10px 0;
    min-height: 0;

    .area-yado-name {
      @include pjt_h5;
    }
  }

  .yado-details {
    display: flex;
  }

  .yado-details-left {
    flex: 1;
    justify-content: left;
  }

  .area-name {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .rate {
    font-size: 14px;
    font-weight: 4000;
  }

  .rating {
    font-weight: bold;
    margin-right: 2px;
  }
  .yado-details-right {
    flex: 1;
    justify-content: right;
  }

  .price-comp {
    margin-bottom: 5px;
  }

  .price {
    display: flex;
    margin-top: 2px;
    justify-content: right;
    gap: 5px;
  }

  .price-info {
    font-size: 9px;
  }

  .per-person {
    text-align: right;
    color: $gray400;

    span {
      font-size: 11px;
    }
  }

  .exclusive-benefits {
    margin: 10px 0;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 160%;
    color: $pjt-benefit-text-brown;
    background-color: $pjt-benefit-bg-yellow;

    .benefit-list {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      max-height: 100px;

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  .benefits-icon {
    margin-right: 5px;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .area-popular-yados {
    margin: 50px auto;

    .area-popular-yados-pc {
      display: none;
    }

    .area-popular-yados-sp {
      display: block;
      max-width: 95%;
      margin: 0 auto;

      .region-select {
        position: relative;
        display: block;
        border: solid 1px $pjt-border;
        border-radius: 3px;
        width: fit-content;
        height: 45px;
        padding: 10px 35px 10px 10px;
        margin-bottom: 25px;

        .region-select-arrow {
          position: absolute;
          top: 45%;
          right: 5px;
        }
      }

      .top-rank-yado {
        max-width: 350px;
        margin: 0 auto;
      }
    }

    .ranked-yados-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .ranked-yados-wrapper {
      display: flex;
      width: 350px;
      margin: 0 auto;

      .rank-number {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-right: 12px;
      }
    }
    .area-popular-yado-card {
      max-width: 100%;

      .card-wrapper {
        display: flex;
      }

      .card-limited-tag {
        width: 94px;
        margin: 5px 0 0 0;
        padding: 0px;
        background-color: $pjt-limited-yellow;
        color: $onsen-white;
        font-size: 12px;
        text-align: center;
        padding: 4px 0;
      }
      .card-image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .card-content-wrapper {
        width: 100%;
        margin-left: 12px;
        padding: 0;

        .yado-name-wrapper {
          margin-bottom: 7px;
        }

        .yado-name {
          margin-bottom: 0px;
          min-height: 0;
        }
      }
      .yado-details {
        display: block;

        .yado-details-left {
          display: flex;
          justify-content: space-between;
        }
        .yado-details-right {
          display: block;
        }
      }
      .exclusive-benefits {
        display: none;
      }
      .area-name {
        font-size: 13px;
      }
      .rate {
        font-size: 13px;
      }
      .price-comp {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-bottom: 0;

        .price-info {
          padding-top: 2px;
        }

        .price {
          font-size: 16px;

          span {
            font-size: 16px;
          }
        }

        .price-jpy {
          padding-top: 3px;
        }
      }
    }
  }
}
