// text-color
$black-text-color: #2f2f2f;
$gray-text-color: rgba(0, 0, 0, 0.54);
$dark-gray-text-color: #333333;
$pink-text-color: #e91e63;

// bg-color
$pink-bg-color: #e91e63;

$breakpoint-xxs-min: 360px;
$breakpoint-sm-min: 576px;
$breakpoint-md-min: 768px;
$breakpoint-lg-min: 992px;
$breakpoint-xl-min: 1200px;

$breakpoint-xs-max: 575.98px;
$breakpoint-sm-max: 767.98px;
$breakpoint-md-max: 991.98px;
$breakpoint-lg-max: 1199.98px;

// Onsen
$onsen-black: #2f2f2f;
$onsen-black80: #2f2f2f80;
$onsen-black20: #2f2f2f20;
$onsen-white: #ffffff;
$onsen-white20: #ffffff20;
$onsen-white40: #ffffff40;
$onsen-light-blue: #f5f9ff;
$onsen-blue: #1d9eef;
$onsen-pink: #e91e63;
$onsen-red: #dd3434;
$onsen-yellow: #ffcc66;
$gray400: #545454;
$gray300: #cecece;
$onsen-gray-900: #2f353e;
$onsen-gray-800: #686868;
$onsen-gray-600: #777777;
$onsen-gray-400: #b3b3b3;
$onsen-gray-300: #dddddd;
$onsen-gray-200: #eeeeee;
$onsen-gray-100: #f7f7f7;
$onsen-gray-border: #e8e8e8;
$onsen-gray-fill: #494949;
$onsen-gray-underline: #cfcfcf;
$onsen-background: #fafafa;
$form-input-gray-border: #e5e5e5;
$form-disabled-border: #e6e6e6;
$onsen-footer-background: #F6F5F5;
$onsen-footer-hover-blue: #007bff;
$onsen-light-blue: #eaf1f8;
$onsen-checkbox-disabled-background: #efefef;
$onsen-border: #e3e3e3;

$support-green: #27C668;
$support-green-light: #E8F4E3;

$onsen-radius-sm: 4px;
$onsen-radius: 8px;

$intro-content-width: 1440px;

$photo-category-lg-max: 1299px;
$photo-category-md-max: 1120px;

@mixin intro-content {
  max-width: $intro-content-width;
  margin: 0 auto;
}

@mixin local_page_ttl {
  font-size: 45px;
  line-height: 150%;
  font-weight: bold;

  @include max-screen($breakpoint-sm-max) {
    font-size: 35px;
  }
}

@mixin local_h1 {
  font-size: 35px;
  line-height: 150%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 25px;
  }
}

@mixin local_h2 {
  font-size: 26px;
  line-height: 150%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 20px;
  }
}

@mixin local_h3 {
  font-size: 20px;
  line-height: 180%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 18px;
  }
}

@mixin local_h4 {
  font-size: 18px;
  line-height: 160%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 16px;
  }
}

@mixin intro_p {
  font-size: 16px;
  line-height: 200%;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 15px;
  }
}

@mixin intro_p_bold {
  font-size: 16px;
  line-height: 250%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 15px;
  }
}

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

// Exclusive Plan PJT
$pjt-blue: #1e2083;
$pjt-background-bluish: #f4f9fd;
$pjt-border: #c9cdd1;
$pjt-background-white: #fefefe;
$pjt-gray-fill: #d9d9d9;
$pjt-light-gray: #d9e4ea;
$pjt-gray: #9b9b9b;
$pjt-dark-gray: #82878e;
$pjt-price-red: #ff1a1a;
$pjt-top-bg-grey: #f5f5f5;
$pjt-limited-yellow: #d99301;
$pjt-benefit-bg-yellow: #f8f1e2;
$pjt-benefit-text-brown: #b27a05;


$pjt-font-l: 17px;
$pjt-font-m: 16px;
$pjt-font-s: 15px;
$pjt-font-xs: 14px;
$pjt-font-xxs: 13px;
$pjt-font-xxxs: 12px;

@mixin pjt_h1 {
  font-size: 32px;
  line-height: 180%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 30px;
  }
}

@mixin pjt_h2 {
  font-size: 28px;
  line-height: 180%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 26px;
    line-height: 170%;
  }
}

@mixin pjt_h3 {
  font-size: 24px;
  line-height: 160%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 22px;
    line-height: 160%;
  }
}

@mixin pjt_h4 {
  font-size: 22px;
  line-height: 160%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 20px;
    line-height: 160%;
  }
}

@mixin pjt_h5 {
  font-size: 20px;
  line-height: 160%;
  font-weight: bold;
  margin-bottom: 0;

  @include max-screen($breakpoint-sm-max) {
    font-size: 20px;
    line-height: 160%;
  }
}

@mixin top_hover($opac: 0.8) {
  transition: opacity 0.3s;

  &:hover {
    opacity: $opac;
  }
}
