.basic-info-container {
  width: 100%;

  #pjt-pick-up {
    scroll-margin-top: 65px;
  }

  .section-buttons {
    display: flex;
    background-color: $onsen-background;
    font-size: $pjt-font-xxs;
    font-weight: 400;
    margin: 5px 0 12px;

    .section-button {
      position: relative;
      padding: 6px 15px;
      color: $pjt-blue;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 25%;
        right: 0;
        width: 0;
        height: 50%;
        border-right: dashed 1px $pjt-dark-gray;
      }
    }
  }

  .kv-image-container {
    position: relative;

    .kv-image {
      width: 100%;
      height: 278px;
      object-fit: cover;
    }

    .kv-image-button {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      position: absolute;
      right: 14px;
      bottom: 18px;
      outline: 0;
      border: 0;
      padding: 3px 10px;

      .image-icon {
        width: 17px;
        height: 17px;
        margin-right: 7px;
        margin-bottom: 1px;
      }

      span {
        color: $onsen-white;
        font-size: $pjt-font-xxxs;
      }
    }
  }

  .pjt-intro-wrapper {
    display: block;
    justify-content: space-between;

    .yado-intro {
      width: 100%;
      color: $dark-gray-text-color;

      .japaneseName {
        display: inline-block;
        color: $gray400;
        font-size: $pjt-font-m;
        font-weight: 400;
        line-height: 100%;
        margin: 10px 0 0;
      }

      .localized-name {
        @include pjt_h1;

        margin: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $onsen-gray-900;
      }

      .catchphrase {
        @include pjt_h4;

        font-weight: 400;
        font-family: Hiragino Mincho Pro;
        margin: 14px 0 25px;
      }

      .summary {
        background: $onsen-background;
        white-space: pre-line;
        padding: 10px 5px;
        font-weight: 400;
        font-size: $pjt-font-s;
      }
    }

    .access-and-map {
      margin-top: 20px;
      padding: 10px;
      color: $dark-gray-text-color;
      height: fit-content;
      width: 100%;
      background: $onsen-background;

      .access-box {
        padding: 10px;
        background: $onsen-white;

        .address {
          font-size: $pjt-font-s;
          font-weight: 400;
          padding-bottom: 10px;
          border-bottom: 1px solid $pjt-border;
        }

        .directions {
          padding: 10px 0 5px 0;
          font-size: $pjt-font-s;
          font-weight: 400;
        }
      }
    }
  }

  .address-content {
    display: none;
  }

  .modal-open-btn-container {
    .modal-open-btn {
      color: $pjt-blue;
      font-size: $pjt-font-xs;

      svg {
        color: $pjt-blue;
        font-size: $pjt-font-xxxs;
      }
    }
  }

  .popular-facility-service {
    margin: 30px 0;

    h5 {
      @include pjt_h5;
    }

    .popular-facility-service-container {
      margin-top: 10px;
      display: flex;
      flex-wrap: nowrap;
      gap: 0 9px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      -webkit-overflow-style: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
      height: 53px;
      max-width: 1230px;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .facility-icon-container {
        display: flex;
        flex-direction: row;
        max-width: none;
        width: auto;
        white-space: nowrap;
        padding: 10px;
        border: 1px solid $pjt-border;
      }

      .facility-icon-background {
        min-height: 33px;
        min-width: 33px;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }

  .yado-rating {
    h2 {
      display: none;
    }

    .rating-container {
      border: 1px solid $form-input-gray-border;
      padding: 10px;

      .rating-total-container {
        max-width: 100%;
        padding: 0;
        justify-content: center;

        .h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          min-height: 50px;
          margin: 0 auto;
          border-right: 1px solid $pjt-border;
        }

        .rating-total-score {
          width: 50%;
          padding: 10px 35px;
          text-align: center;
          display: block;
          color: $dark-gray-text-color;

          .score {
            @include pjt_h5;
          }

          .score-message {
            font-size: $pjt-font-xs;
          }

          .rating-stars {
            padding-left: 3px;
            justify-content: center;
          }
        }
      }
    }

    .detail-rating-container {
      justify-content: space-around;
      padding-right: 0;
      column-gap: 10px;
      grid-template-columns: repeat(auto-fill, 150px);
    }
  }

  .photo-image-wrapper {
    padding: 40px 0;
    border-bottom: 1px solid $pjt-border;

    h3 {
      margin-bottom: 10px;
    }
  }

  .photo-image-container {
    display: flex;
    justify-content: space-between;

    &.flex-start {
      justify-content: flex-start;
      gap: 0 10px;
    }
  }

  .photo-image {
    object-fit: cover;
    max-width: 110px;
    height: 85px;
  }

  .see-more {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .features-wrapper,
  .access-wrapper,
  .facility-wrapper,
  .yado-recommendation {
    max-width: 950px;
    margin: 60px auto;
  }

  .features-wrapper {
    padding: 60px 0;
    margin: 0 auto;
    border-bottom: 1px solid $pjt-border;

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    .feature-image {
      width: 100%;
      max-width: 100%;
    }
  }

  .feature-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .feature-text {
      width: 100%;
      margin: 10px 0 0 0;
      font-size: $pjt-font-l;
      font-weight: 400;
      line-height: 190%;

      h3 {
        margin-bottom: 10px;
      }
    }
  }

  .feature-2 {
    display: flex;
    flex-direction: column-reverse;
    margin: 30px 0;

    .feature-text {
      margin: 10px 0 0 0;
    }
  }

  .experience-btn {
    border: none;
    border-radius: 3px;
    font-size: $pjt-font-s;
    width: 320px;
    min-height: 55px;
    display: block;
    margin: 50px auto 0;
    color: $onsen-white;
    padding: 10px 20px;
    background-color: $pjt-blue;
  }

  .facilities-title {
    margin-top: 30px;
  }

  .access-facilities-table {
    border-top: 1px solid $onsen-gray-border;
    font-size: $pjt-font-m;
    margin-top: 10px;
    line-height: 185%;

    &:nth-child(2) {
      margin-bottom: 46px;
    }
    .access-facilities-table-row {
      display: block;
      border-bottom: 1px solid $onsen-gray-border;

      .access-facilities-table-row-heading {
        flex: 1 1 25%;
        background-color: $onsen-background;
        font-weight: 700;
        padding: 10px;
      }

      .access-facilities-table-row-content {
        flex: 1 1 75%;
        padding: 8px 10px 9px;

        &.bath-items {
          div {
            display: inline;
          }
        }
      }
    }

    .facilities-access {
      white-space: pre-wrap;
    }

    .row-grid-container {
      column-gap: 66px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 50%;

      &.mobile-block {
        display: block;
      }
    }

    .note {
      white-space: pre-line;
    }

    .toggle-btn-wrapper {
      margin-top: 27px;
      text-align: center;

      .toggle-btn {
        height: 42px;
        width: 252px;
        color: $pjt-blue;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $onsen-white;
        border: 1px solid $pjt-blue;
        font-size: $pjt-font-m;
        align-items: center;
        justify-content: center;

        &:focus {
          outline: none;
        }
      }
    }

    .ticket-description span {
      display: block;
      font-weight: 700;
    }
  }

  .pjt-bath-section {
    margin: 50px 0;

    .foot-note {
      font-size: $pjt-font-s;
    }

    .private-bath-item {
      p {
        font-size: $pjt-font-s;
      }
    }
  }

  .pjt-facility-label {
    margin-top: 30px;
    text-wrap: wrap;

    .facility-label-container {
      width: 100%;
      padding: 10px 20px;
      font-size: $pjt-font-m;
      white-space: pre-line;
    }

    .facility-label-icon {
      height: 23px;
      width: 27px;
      margin-right: 10px;
    }
  }

  .bath-photo-gallery {
    max-width: 100%;
    overflow: hidden;
    position: relative;

    .image-gallery-slides {
      background-color: $black-text-color;
      border-radius: 0;
      text-align: center;
    }

    .image-gallery-slide {
      background-color: transparent;
      opacity: 0.5;
      transition: opacity 0.2s ease-out;

      img {
        width: 100%;
        height: 270px;
        object-fit: cover;
      }
    }

    .image-gallery-slide.center {
      opacity: 1;
    }

    .image-gallery-custom-button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      position: absolute;
      height: 50px;
      width: 50px;
      top: 47%;
      z-index: 2;

      &:focus {
        outline: 0;
      }

      &.left-nav {
        left: 7px;
      }

      &.right-nav {
        right: 7px;
      }

      img {
        height: 50px;
        width: 50px;
      }
    }

    .image-gallery-bullet {
      background-color: $onsen-white;
      opacity: 0.6;

      &.active {
        opacity: 1;
      }
    }
  }

  .bath-notice-points {
    margin-top: 12px;

    p {
      color: $gray400;
      font-size: $pjt-font-s;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  .pjt-pickup-section {
    .facilities-pickup {
      border: 1px solid $pjt-border;
      padding: 15px 10px;
      margin: 15px 0;

      .pickup-notes {
        p {
          font-size: $pjt-font-s;
        }
      }

      .pickup-location {
        div {
          font-size: $pjt-font-s;
        }
      }
    }

    .pickup-heading {
      @include pjt_h5;

      border-bottom: none;
    }

    .pickup-location {
      background-color: $onsen-background;
    }

    p,
    div {
      font-size: $pjt-font-m;
    }
  }
}

@include mobile {
  .basic-info-container {
    .features-wrapper {
      .feature-image {
        max-width: 503px;
      }
    }

    #pjt-pick-up {
      scroll-margin-top: 90px;
    }

    .section-buttons {
      font-size: $pjt-font-s;

      .section-button {
        padding: 9px 20px;
      }
    }
    .kv-image-container {
      .kv-image {
        height: 350px;
      }
    }

    .yado-rating {
      .rating-container {
        .rating-total-container {
          max-width: 385px;

          .rating-total-score {
            padding: 10px 45px;

            .score {
              @include pjt_h3;
            }

            .score-message {
              font-size: $pjt-font-m;
            }
          }
        }
      }
    }

    .photo-image {
      max-width: 180px;
      height: 126px;
    }

    .feature-container {
      .feature-text {
        width: 65%;
      }
    }

    .access-facilities-table {
      .access-facilities-table-row {
        display: flex;
        flex-wrap: wrap;
      }

      .toggle-btn-wrapper {
        text-align: right;
      }
    }

    .bath-photo-gallery {
      .image-gallery-slide {
        img {
          height: 380px;
        }
      }
    }
  }
}

@include ipad {
  .basic-info-container {
    .kv-image-container {
      .kv-image {
        height: 530px;
      }
    }

    .pjt-intro-wrapper {
      display: flex;

      .yado-intro {
        width: 65%;
        margin-right: 5%;

        .catchphrase {
          margin: 24px 0 30px;
        }

        .summary {
          font-size: $pjt-font-m;
        }
      }

      .access-and-map {
        margin-top: 0;
        width: 315px;
      }
    }

    .photo-image {
      max-width: 240px;
      height: 171px;
    }

    .feature-container {
      flex-direction: row;

      .feature-text {
        width: 45%;
        margin: 0 5% 0 0;

        h3 {
          margin-bottom: 25px;
        }
      }
    }

    .feature-2 {
      flex-direction: row-reverse;

      .feature-text {
        margin: 0 0 0 5%;
      }
    }

    .access-facilities-table {
      .row-grid-container {
        &.mobile-block {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          max-width: 700px;
          width: auto;
        }
      }
    }

    .yado-rating {
      .detail-rating-container {
        justify-content: flex-start;
        column-gap: 30px;
      }
    }
  }
}

@include tablet {
  .basic-info-container {
    .photo-image {
      max-width: 320px;
      height: 222px;
    }

    .bath-photo-gallery {
      .image-gallery-slide {
        img {
          height: 460px;
        }
      }
    }
  }
}

@include desktop {
  .basic-info-container {
    .photo-image-container {
      &.flex-start {
        gap: 0 20px;
      }
    }

    .photo-image {
      max-width: 380px;
      width: 380px;
      height: 273px;
    }

    .yado-rating {
      .detail-rating-container {
        padding-right: 30px;
        column-gap: 50px;
      }
    }
  }
}
