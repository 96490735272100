@import 'variables';

.h1 {
  @include pjt_h1;
}

.h2 {
  @include pjt_h2;
}

.h3 {
  @include pjt_h3;
}

.h4 {
  @include pjt_h4;
}

.h5 {
  @include pjt_h5;
}

.h6 {
  font-size: 15px;
  color: $black-text-color;
  letter-spacing: 0;
  line-height: 23px;
  font-weight: 700;
}
.caption {
  font-size: 10.5px;
  color: #686868;
  letter-spacing: 0;
  line-height: 1.4em;
}
.order-detail-box-caption {
  font-size: $pjt-font-xxs;
  color: $gray400;
  letter-spacing: 0;
  line-height: 20.8px;
  margin: 0;
}
.bold-heading {
  color: $dark-gray-text-color;
  font-weight: 700;
  line-height: 1.5;
}
