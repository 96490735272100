@import 'variables';

.select-regions-container {
  .select-regions-content {
    margin: 0 auto;
    padding: 25px 10px 38px;

    &.footer {
      padding: 0;

      .select-regions {
        position: unset;

        .title {
          line-height: 1;
          font-weight: 400;
          color: $dark-gray-text-color;
          display: inline-block;
          padding: 6px 12px 6px 0;
          margin: 2px 12px 2px 0;
          position: relative;
          word-break: break-word;

          &:hover {
            color: $onsen-footer-hover-blue;
          }

          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 50%;
            top: 50%;
            background: #e3e3e3;
            transform: translate(12px, -6px);
          }
        }
      }
    }

    h2 {
      line-height: 1.5;
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    h3 {
      padding: 0;
      font-size: 0.9375rem;
      font-weight: bold;
      line-height: 1;
      color: #2f2f2f;
      margin-bottom: 10px;
    }

    .select-regions {
      position: relative;

      .carousel-container {
        position: unset;
        .react-multiple-carousel__arrow--left {
          left: -22px;
        }

        .react-multiple-carousel__arrow--right {
          right: -22px;
        }
      }

      .image {
        width: fit-content;
        position: relative;
        display: block;
        border-radius: 5px;
        overflow: hidden;

        img {
          filter: brightness(0.7);
        }

        .title {
          text-align: center;
          font-size: 1rem;
          color: $onsen-white;
          font-weight: 700;
          line-height: 28px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 70%;
        }
      }
    }
  }
}

.areas-title {
  color: $dark-gray-text-color;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
}

@include mobile {
  .select-regions-container {
    .select-regions-content {
      padding: 40px 30px;
    }
  }
}

@include ipad {
  .select-regions-container {
    .select-regions-content {
      &.footer {
        margin: 0;
      }
      max-width: 720px;
      padding: 40px 0;

      h2 {
        margin-bottom: 30px;
      }

      .select-regions {
        .image {
          .title {
            font-size: 1.1875rem;
          }
        }
      }
    }
  }
}

@include tablet {
  .select-regions-container {
    .select-regions-content {
      max-width: 960px;
    }
  }
}

@include desktop {
  .select-regions-container {
    .select-regions-content {
      max-width: 1160px;
    }
  }
}
