.intro-recom-wrapper {
  background: linear-gradient(to bottom, $onsen-background, $onsen-white);
}

.about-recommend-sp {
  display: none;
}

.intro-recom {
  @include intro-content;
  position: relative;
  padding: 120px 0 150px 0;
  min-height: 700px;
}
.intro-columns-wrapper {
  display: flex;
  max-width: 100%;
}
.intro-column {
  position: relative;
  flex: 1;
}
.intro-recom-text {
  position: relative;
  float: right;
  max-width: 501px;
  margin: 100px 160px 70px 0px;
  padding-left: 20px;
}

.intro-recom-title {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1.3px;
  margin-bottom: 19px;
}

.intro-recom-description {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 200%; 
  margin-top: 19px;
  
}

.background-pattern {
  position: absolute;
  top: 330px;
  border-radius: 50%;
}
.recom-img-1-wrapper {
  width: 90%;
  max-height: 300px;
  max-width: 700px;
  aspect-ratio: 7/3;
  display: flex;
  justify-content: right;
  margin-left: 10px;
  float: right;
}
.recom-img-1 {
  position: relative;
  width: 100%;
  max-height: 300px;
  max-width: 700px;
  
}
.recom-img-2 {
  width: 100%;
  height: auto;
  max-width: 680px;
  max-height: 600px;
  aspect-ratio: 68/60;
  margin-top: 40px;
  margin-bottom: 40px;
}
.recom-img-3 {
  width: 90%;
  max-width: 560px;
  max-height: 270px;
  aspect-ratio: 56/27;
  margin: 9px 0 0 65px;
}
.recom-img-4 {
  width: 80%;
  max-width: 260px;
  max-height: 335px; 
  aspect-ratio: 260/335;
  margin: 60px 10px 10px 45px;
}
.recom-img-5-wrapper {
  width: 90%;
  max-width: 300px;
  max-height: 280px;
  margin: 20px 10px 10px -15px;
}
.recom-img-5 {
  aspect-ratio: 15/14;
  max-width: 300px;
  max-height: 280px;
}

@media screen and (max-width: $breakpoint-md-max) {
  .about-recommend-sp {
    display: block;
  }

  .about-recommend-pc {
    display: none;
  }

  .intro-recom {
    max-width: 768px;
  }
  .intro-recom::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -24px;
    display: block;
    width: 1px; 
    height: 50px; 
    background-color: black;
  }
  .intro-recom-title {
    font-size: 21px;
    font-weight: bold;
  }
  .intro-top-description {
    font-size: 16px;
    padding: 20px 0px;
    font-weight: bold;
    line-height: 28px;
    white-space: pre-line;
  }
  .intro-columns-wrapper {
    width: 100%;
    display: flex;
  }
  .intro-column {
    flex: 50%;
  }
  .intro-recom {
    position: relative;
    background: linear-gradient(to bottom, $onsen-background, $onsen-white);
    padding: 40px 0 100px 0;
  }

  .background-pattern {
    position: absolute;
    top: 140px;
    width: 192px;
    border-radius: 50%;
  }

  .intro-recom-title {
    font-size: 20px;
  }

  .intro-recom-text {
    padding: 40px 20px;
    position: relative;
    float: left;
    font-size: 12px;
    margin: 0;
    max-width: 100%;
  }

  .intro-recom-description {
    @include intro_p_bold;
    margin-top: 15px;
  }

  .recom-img-2 {
    max-width: 80%;
    min-width: 240px;
    min-height: 210px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .recom-img-1-position {
    display: flex;
    justify-content: right;
  }

  .recom-img-1-wrapper {
    float: none;
    min-width: 310px;
  }

  .recom-img-3-wrapper {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .recom-img-1 {
    display: flex;
    justify-content: right;
    position: relative;
    min-width: 310px;
    min-height: 160px;
    object-fit: cover;
  }

  .recom-img-3 {
    width: 80%;
    float: right;
    height: auto;
    position: relative;
    right: 0;
    margin: 30px 0 0 0;
    aspect-ratio: 56/27;
  }
  .recom-img-4 {
    min-width: 140px;
    min-height: 180px;
    margin: 0 0 0 20px;
  }

  .recom-img-5-wrapper {
    max-width: 100%;
    min-width: 150px;
    min-height: 145px;
    
  }
  .recom-img-5 {
    max-width: 100%;
    min-width: 150px;
    min-height: 145px;
    margin-top: -15px;
  }
}