.popular-areas {
  margin: 50px auto 30px;
  padding: 0 12px;

  .popular-areas-header {
    position: relative;
    margin-bottom: 25px;
  }

  .popular-keyword-header {
    h3 {
      margin-bottom: 0;
    }
  }

  .popular-areas-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    .popular-areas-container {
      .link {
        .image-container {
          border-radius: 3px;
        }
      }
      .text {
        display: none;
      }
    }
  }

  .popular-keyword-title {
    font-size: 20px;
  }
  
  .prefectures-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 32px;
  }

  .pref-card {
    position: relative;
    width: 173px;
    height: 120px;
    @include top_hover;
    

    .area-img-wrapper {
      position: relative;
      
      .pref-img {
        width: 173px;
        height: 120px;
      }
    }
  
    .area-details {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .area-title {
      width: fit-content;
      height: auto;
      position: relative;
      top: calc(50% - 10px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: $onsen-white;
      margin-right: 5px;
    }
  }

  .see-more {
    position: absolute;
    right: 0;
    bottom: 10px;
    padding-right: 0px;
    

    a {
      color: $pjt-blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $pjt-blue;
      }
    }
  }
}

@include ipad {
  .popular-areas {
    padding: 0 10px;

    .popular-areas-header {
      margin-bottom: 35px;
    }

    .popular-areas-wrapper {
      gap: 17px;
    }
  }
}

@include tablet {
  .popular-areas {
    padding: 0;
    max-width: 960px;
    width: 960px;

    .popular-areas-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}

@include desktop {
  .popular-areas {
    max-width: 1163px;
    width: 1163px;
    margin: 100px auto;

    .popular-areas-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}

@include max-screen($breakpoint-md-max) {
.popular-areas {
  .simple-areas-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
  }

  .simple-area {
    width: fit-content;
    padding: 8px 15px;
    align-items: center;
    border-radius: 25px;
    border: solid 1px $pjt-border;
    font-weight: bold;
  }

  .popular-keyword-title {
    font-size: 20px;
  }

  .prefectures-wrapper {
    width: 100%;
    gap: 8px;
    
    .pref-card {
      width: calc(50% - 4px);

      .area-img-wrapper {
        margin: 0 0;
        max-width: 100%;
        overflow: hidden;
  
        .pref-img {
          width: 100%;
          aspect-ratio: 1.45;
          object-fit: cover;
        }
      }
    }
  }
}
}