.meal-explanation-wrapper {
  background-color: $onsen-background;
}

.onsen-explanation-wrapper {
  background-color: $onsen-background;
  padding: 20px 0;
  margin-bottom: 75px;
}

.meal-explanation, .onsen-explanation {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 20px;
}

.meal-venues, .meal-types {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin: 35px 0;
}

.meal-details {
  max-width: $intro-content-width;
}

.meal-venue {
  flex-shrink: 0;
  background-color: $onsen-white;
  max-width: 260px;
  width: auto;
  height: auto;
  padding: 10px 10px 0 10px;
}

.venue-img {
  aspect-ratio: 5/3;
  max-width: 100%;
  object-fit: cover;
}

.onsen-type-img {
  aspect-ratio: 5/3;
  max-width: 100%;
  object-fit: cover;
  margin-bottom: 5px;
}

.meal-type {
  text-align: center;
  background-color: $onsen-white;
  max-width: 220px;
  padding: 10px;
}

.type-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.meal-howto-section {
  margin-top: 75px;
}

.meal-howto {
  background-color: $onsen-background;
  width: 85%;
  margin: 0 auto;
  padding: 40px 0;
}

.onsen-types {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
}

.onsen-type {
  flex: 1;
  background-color: $onsen-white;
  padding: 10px;

  h3 {
    margin-top: 5px;
  }
}

.pickup-summary {
  p {
    text-align: center;
  }

}

.step-4 {
  margin-top: -2px;
}

.pickup-summary-tables-sp {
  display: none;
}

.pickup-summary-tables-pc {
  display: block;
}

.pickup-summary-tables {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;

  table {
    border: 2px solid $onsen-gray-400;
    font-weight: bold;
  }

  th {
    border-bottom: 1px solid $onsen-gray-800;
    background-color: $onsen-gray-800;
    padding: 5px 30px;
    font-size: 20px;
    color: $onsen-white;
  }
  
  td {
    border: 1px solid $onsen-gray-800;
    padding: 20px 10px;
    font-size: 16px;
  }
}

.pickup-howto-steps {
  text-align: center;
  padding: 40px 0;
}

.table-step {
  display: flex;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
}

.step-circle {
  width: 24px;
  height: 24px;
  color: $onsen-white;
  border-radius: 50%;
  background-color: $onsen-gray-800;
  margin-right: 5px;
  margin-top: 4px;
}

.pickup-step {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .step-circle {
    margin-top: 8px;
  }
}

.pickup-simple-text {
  max-width: 800px;
  margin: 0 auto;
  line-height: 34px;
}

@media screen and (max-width: $breakpoint-md-max) {
  .meal-explanation {
    background-color: $onsen-background;
    width: 100%;
    margin: 20px auto 55px auto;
    padding: 20px 20px;
  }

  .pickup-summary-tables-sp {
    display: block;
  }
  
  .pickup-summary-tables-pc {
    display: none;
  }
  
  .onsen-explanation {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}
  
  .meal-venues {
    display: block;
    justify-content: space-between;
    gap: 5px;
    margin: 30px 0;
  }
  
  .meal-venue {
    background-color: $onsen-white;
    max-width: 100%;
    height: auto;
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
  }
  
  .venue-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .meal-types {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
  }
  
  .meal-type {
    flex: 1;
    text-align: center;
    background-color: $onsen-white;
    min-width: 48%;
    max-width: 165px;
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
  }
  .onsen-explanation-wrapper {
    background-color: $onsen-background;
    padding: 20px 20px 40px 20px;
    margin-bottom: 75px;
  }

  .onsen-types {
    display: block;
    justify-content: center;
  }

  .onsen-type {
    margin-top: 10px;
  }
  
  .type-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 5px;
  }
  
  .meal-howto {
    background-color: $onsen-background;
    width: 90%;
    margin: 0 auto;
    padding: 20px 10px;
  }

  .pickup-step {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .step-circle {
      margin-top: 3px;
    }
  }

  .meal-howto-section {
    margin-top: 0px;
  }

  .pickup-summary-tables {
    display: block;
    justify-content: center;
    gap: 10px;
    text-align: center;
  
    table {
      width: 100%;
      border: 2px solid $onsen-gray-400;
      font-weight: bold;
      margin: 10px 0;
    }
  
    th {
      border-bottom: 1px solid $onsen-gray-800;
      background-color: $onsen-gray-800;
      padding: 5px 30px;
      font-size: 20px;
      color: $onsen-white;
    }
    
    td {
      border: 1px solid $onsen-gray-800;
      padding: 20px 10px;
      font-size: 16px;
    }
  }
  
}