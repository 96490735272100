.foot-big {
  width: 100%;
  background-color: $onsen-footer-background;
  border-top: solid 1px $onsen-border;

  .foot-big-wrapper {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 0.9375rem;

    .go-top-btn {
      position: absolute;
      right: 0;
      top: 5%;
    }

    .heading {
      font-weight: bold;
      line-height: 1;
      color: #2f2f2f;
      margin-bottom: 10px;
    }
  }

  .item-wrapper {
    display: flex;
    flex-wrap: wrap;

    .span-wrapper {
      font-weight: 400;
      font-size: 0.9375rem;
      display: inline-block;
      padding-right: 12px;
      margin: 2px 12px 2px 0;
      position: relative;
      word-break: break-word;

      &:last-child {
        padding-right: 0;
      }

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 50%;
        top: 50%;
        background: #e3e3e3;
        transform: translate(12px, -6px);
      }
    }
  }
}

@include ipad {
  .foot-big {
    .foot-big-wrapper {
      padding: 22px 0 25px;
      width: 90%;
    }
  }
}

@include tablet {
  .foot-big {
    .foot-big-wrapper {
      max-width: 970px;
    }
  }
}

@include desktop {
  .foot-big {
    .foot-big-wrapper {
      max-width: 1163px;
    }
  }
}
