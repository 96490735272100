@import '../../variables.scss';

.price-range-select-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.price-range-select {
  width: 47%;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid $onsen-gray-300;
  font-size: 13px;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  background: no-repeat url(/yado/onsen/assets/images/pjt/arrow_blue_down.svg);
  background-size: 12px;
  background-position: right 7px top 16px;

  &:focus, &:focus-visible {
    outline: none;
  }
}
