@import '../../variables.scss';

@include max-screen($breakpoint-xs-max) {
  .DayPickerNavigation__verticalScrollable_prevNav {
    display: none;
  }
  .DayPicker_weekHeader_ul {
    bottom: 0 !important;
  }
}
