.popular-areas {
  .area-card-link {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }

  .popular-areas-title {
    @include pjt_h2;
  }

  .area-card-wrapper {
    position: relative;
    display: flex;
    justify-content: left;
    width: 277px;
    height: 120px;
    border: 1px solid $pjt-border;
    transition: border 0.3s;

    @include top_hover;

    .area-img-wrapper {
      position: relative;

      .area-img {
        width: 277px;
        height: 120px;
        object-fit: contain;
      }
    }

    .area-details {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .area-title {
      width: fit-content;
      height: auto;
      position: relative;
      top: calc(50% - 10px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      font-weight: bold;
      color: $onsen-white;
      margin-right: 5px;
    }
    .area-region-pref {
      font-size: 14px;
      font-weight: bold;
    }
    .area-howto {
      font-size: 14px;
    }

    .area-howto-en {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 50px;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .popular-areas {
    .popular-areas-title {
      width: 70vw;
    }
  }
  .popular-areas-wrapper {
    gap: 8px;

    .onsen-area-card {
      width: calc(50% - 4px);
    }

    .area-card-wrapper {
      display: flex;
      justify-content: left;
      width: 100%;
      aspect-ratio: 1.45;
      border: none;

      &:hover {
        background-color: $pjt-background-bluish;
      }

      .area-img-wrapper {
        margin: 0 0;
        max-width: 100%;
        overflow: hidden;

        .area-img {
          width: 100%;
          object-fit: cover;
        }
      }

      .area-details {
        display: block;
        width: 100%;
        flex-direction: column;
        justify-content: auto;
        align-items: flex-start;
        gap: 0;
        flex: 1 0 0;
      }

      .area-title {
        width: fit-content;
        height: auto;
        position: relative;
        top: calc(50% - 10px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        font-weight: bold;
        color: $onsen-white;
      }
      .area-region-pref {
        font-size: 14px;
        font-weight: bold;
      }
      .area-howto {
        font-size: 14px;
      }
    }
    .area-card-wrapper-en {
      height: auto;
      min-height: 83px;
    }
  }
}
