.cancel-policy--period,
.cancel-policy--value {
  font-size: $pjt-font-l;
  color: $dark-gray-text-color;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 400;
}
.cancel-policy--dotted-line {
  border-bottom: 1px dashed $pjt-border;
  flex-grow: 1;
  margin: 0 5px;
  vertical-align: middle;
  padding: 10px 0 10px 0;
  position: relative;
  bottom: 3px;
}

@include max-screen($breakpoint-xs-max) {
    .cancel-policy--period,
    .cancel-policy--value {
        font-size: $pjt-font-m;
    }
  }


