.how-to-steps {
  display: flex;
  justify-content: center;
  gap: 56px;
  padding: 0 20px;
}

.how-to-step {
  flex: 1;
  position: relative;
  max-width: 470px;

  .how-to-img {
    max-width: 100%;
  }

  .how-to-text, p {
    margin-top: 10px;
    white-space: pre-line;
    line-height: 30px;
    text-align: left;
  }

  .how-to-link {
    color: $onsen-black;
    font-weight: bold;
    text-decoration: underline;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: -40px;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;     
    border-bottom: 16px solid transparent;  
    border-left: 20px solid $onsen-gray-800;          
  }

  &:last-child {
    &::after {
      border-top: 0;    
      border-bottom: 0;  
      border-left: 0;          
    }
  }
}

.how-to-warn {
  font-weight: bold;
}

@media screen and (max-width: $breakpoint-md-max) {
  .how-to-steps {
    display: block;
    justify-content: center;
    gap: 56px;
    padding: 0px;
  }
  
  .how-to-step {
    position: relative;
    margin-bottom: 50px;
    margin: 56px auto;
    padding: 0px;
  
    p {
      margin-top: 10px;
      white-space: pre-line;
    }
  
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 103%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: 20px solid $onsen-gray-800;     
      border-right: 16px solid transparent;  
      border-left: 16px solid transparent;          
    }
  
    &:last-child {
      margin-bottom: 0;

      &::after {
        border-top: 0;    
        border-bottom: 0;  
        border-left: 0;          
      }
    }
  }
}