:lang(en) {
  @media screen and (max-width: 576px) {
    .input-wrapper.jsx-615150858 .search-input,
    .suggestion-display-input {
      font-size: 0.6875rem !important;
      letter-spacing: unset !important;
      transform: scale(1) !important;
    }
  }
}
