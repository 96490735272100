.select-image-yados-container {
  padding: 25px 0 0;

  h2 {
    font-size: 1.25rem;
    line-height: 1.5;
    padding-left: 12px;
    margin-bottom: 20px;
  }

  .select-yados {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0 auto;
    width: 95%;
  }

  .select-yado-item {
    position: relative;
    width: 85px;
    height: 85px;
  }
}

@include mobile {
  .select-image-yados-container {
    padding: 40px 0 0;

    h2 {
      font-size: 1.25rem;
      padding-left: 30px;
      margin-bottom: 30px;
    }

    .select-yados {
      gap: 11px;
      width: 90%;
      max-width: 700px;
      margin-left: 30px;
    }

    .select-yado-item {
      width: 125px;
      height: 125px;
    }
  }
}

@include tablet {
  .select-image-yados-container {
    padding: 40px 0 0;
    max-width: 960px;
    margin: 0 auto;

    h2 {
      padding-left: 0;
      margin-bottom: 30px;
    }

    .select-yados {
      gap: 11px;
      width: 100%;
      max-width: 1166px;
      margin-left: 0;
    }
  }
}

@include desktop {
  .select-image-yados-container {
    max-width: 1166px;

    .select-yado-item {
      width: 156px;
      height: 156px;
    }
  }
}
