.intro-page {
  margin: 0 auto;
  letter-spacing: 0.8px;
  white-space: pre-wrap;

  h1 {
    @include local_h1;
  }

  h2 {
    @include local_h2;
  }

  h3 {
    @include local_h3;
  }

  h4 {
    @include local_h4;
  }

  p {
    @include intro_p;
  }

  .p-bold {
    @include intro_p_bold;
  }

  .intro-top {
    position: relative;
    margin: 0 auto;
    max-width: $intro-content-width;
    padding-top: 30px;
    height: 860px;
  }
  .intro-top-text {
    max-width: 33%;
    margin: 40px 0 0 105px;
    white-space: pre-wrap;
  }
  .intro-logo {
    height: 50px;
    margin-bottom: 10px;
    margin-left: -18px;

    &.en-logo {
      margin-left: -5px;
      margin-bottom: 40px;
    }

    img {
      width: 75%;
    }
  }
  .intro-columns-wrapper {
    display: flex;
  }
  .intro-column {
    flex: 50%;
  }
  .intro-top-title {
    font-size: 35px;
    font-weight: bold;
  }
  .intro-top-description {
    padding: 10px 0;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 5%;
  }
  .img-1-wrapper {
    max-width: 630px;
    width: 47%;
  }
  .intro-img-1 {
    position: relative;
    max-width: 630px;
    width: 100%;
  }
  .intro-img-2 {
    position: absolute;
    max-width: 750px;
    width: 51.7%;
    top: 58px;
    right: 0;
    object-fit: cover;
  }

  .intro-img-2-sp {
    display: none;
  }
  .img-belt {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
  .intro-section-header {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 75px;

    h2 {
      font-size: 23px;
    }
  }
  .intro-section-header::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 2px;
    background-color: $onsen-black;
  }
}

.yado-image {
  position: relative;
  width: auto;
  height: fit-content;
}

.yado-image-text {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: $onsen-white;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; 
  z-index: 10;
  text-align: right;
  white-space: pre;

  &:hover {
    text-decoration: underline;
  }
}

.search-yados-bottom {
  margin-bottom: 148px;
}

@media screen and (max-width: $breakpoint-md-max) {
  .intro-page {
    max-width: 100%;
    margin: 0 auto;

    .intro-top {
      max-width: 100vw;
      position: relative;
      padding-top: 0;
      min-height: 0;
      height: auto;
      margin-bottom: 42px;
    }
    .intro-top-title {
      max-width: 100%;
      font-size: 25px;
      white-space: pre-wrap;
    }
    .intro-top-text {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
      margin: auto;
      text-align: center;
    }
    .intro-logo {
      height: 40px;
      margin: 30px auto;

      &.en-logo {
        margin-bottom: 45px;

        img {
          width: 40%;
        }
      }

      img {
        width: 50%;
      }
    }
    .intro-columns-wrapper {
      width: 100%;
      display: flex;
    }
    .intro-column {
      flex: 50%;
    }
    .intro-top-description {
      width: 100%;
      font-weight: bold;
      white-space: pre-line;
    }
    .img-1-wrapper {
      width: 100%;
      max-width: 100%;
      aspect-ratio: 375/180;
      min-height: 180px;
      margin: 0;
    }
    .intro-img-1 {
      width: 100%;
      max-width: 100%;
      aspect-ratio: 375/180;
      min-height: 180px;
    }
    .intro-img-2-sp {
      display: block;
      position: relative;
      top: 0;
      width: 100%;
      max-width: 100%;
      aspect-ratio: 375/180;
      min-height: 180px;
      object-fit: cover;
      object-position: 0 0;
    }

    .intro-img-2 {
      display: none;
    }
    .img-belt {
      width: 100%;
      height: 140px;
      object-fit: cover;
    }
    .intro-section-header {
      position: relative;
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px auto 42px auto;
      h2 {
        font-size: 18px;
      }
    }
    .intro-section-header::after {
      content: '';
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 2px;
      background-color: $onsen-black;
    }
    .search-yados-bottom {
      margin-bottom: 100px;
    }

    .yado-image-text {
      font-size: 12px;
      z-index: 10;
      text-align: right;
      white-space: pre;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .intro-page {
    .intro-logo {
      height: 40px;
      margin: 30px auto 10px auto;

      &.en-logo {
        margin-bottom: 15px;

        img {
          width: 60%;
        }
      }

      img {
        width: 70%;
      }
    }
  }
}
