.photo-category-title {
  display: inline;

  h2 {
    padding: 10px 0;
    margin: 0;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 5px;
    margin: 0 5px;
    cursor: pointer;
  }

  .return-button {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    padding: 15px 20px;
    color: #2187f2;
    outline: 0;
    border: 0;
  }
}

.category-images-container {
  .tab-panel-container {
    margin: 0 auto;
    max-width: 1140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px 5px;

    button {
      padding: 0;
      background-color: transparent;
      outline: 0;
      border: 0;
    }
  }

  .MuiPaper-root {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;

    &.MuiPaper-elevation4 {
      box-shadow: none !important;
    }
  }

  .MuiTabs-root {
    padding-left: calc((100% - 1140px) / 2);
    padding-right: calc((100% - 1140px) / 2);
  }

  .album-mobile-item {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 75%;
    }
  }

  .photo-panel-container {
    max-width: 1140px;
    margin: 0 auto;

    .gallery-image-container {
      min-width: 720px;
      width: 720px;
      margin-right: 35px;
    }
  }
}

@include max-screen($photo-category-lg-max) {
  .category-images-container {
    .tab-panel-container {
      max-width: 910px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .MuiTabs-root {
      padding-left: calc((100% - 910px) / 2);
      padding-right: calc((100% - 910px) / 2);
    }

    .photo-panel-container {
      .gallery-image-container {
        min-width: 600px;
        width: 600px;
      }
    }
  }
}

@include max-screen($breakpoint-lg-min) {
  .category-images-container {
    .tab-panel-container {
      max-width: 700px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .MuiTabs-root {
      padding-left: calc((100% - 700px) / 2);
      padding-right: calc((100% - 700px) / 2);
    }

    .photo-panel-container {
      .gallery-image-container {
        min-width: 100%;
        width: 100%;
      }
    }
  }
}

@include max-screen($breakpoint-md-min) {
  .category-images-container {
    .MuiBox-root {
      padding: 10px !important;
    }
  }
}
