@import './breakpoints.scss';
.payment-container {
  padding: 20px 10px;
  border-radius: 3px;
  border: 1px solid #e3e3e3;

  h5 {
    font-size: 20px;
    letter-spacing: 0.53px;
    color: $dark-gray-text-color;
    display: inline-block;
  }

  .payment-title {
    display: flex;
    align-items: center;

    img {
      max-height: 24px;
    }
  }

  .credit-card-container {
    display: block;
  }

  .supported-card {
    font-size: $pjt-font-xs;
    color: #9b9b9b;
    opacity: 0.7;
    line-height: 21px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 25px;
  }

  .afteepay-selected {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid $pjt-blue;
    cursor: default;
  }

  .aftee-logo {
    height: 26px; 
  }

  ul {
    padding-left: 20px;
    font-size: $pjt-font-s;
    font-weight: normal;
    line-height: 1.64;
    letter-spacing: 0.62px;
    color: #2f2f2f;
  }

  .pay-deadline {
    width: fit-content;
    padding: 0.5rem;
    background-color: #e8f4e3;
    font-size: $pjt-font-s;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.62px;
    color: #2f2f2f;

    &.bold {
      font-weight: 600;
    }
  }

  .form-label-disabled {
    color: #9b9b9b;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

.aftee-btn {
  padding: 0;
  background-color: transparent;
  border: none;
  color: $pjt-blue;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
}
.aftee-btn:focus {
  outline: 0;
}

@include mobile {
  .payment-container {
    padding: 20px;

    ul {
      padding-left: 46px;
      font-size: $pjt-font-m;
    }

    .form-label-disabled {
      flex-direction: row;
      align-items: center;
    }
  }

}
