.top-kv-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 580px;
  min-height: 580px;

  @keyframes pan-image {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-10%); 
    }
  }
  
  .kv-image {
    width: 112%;
    height: 112%;
    overflow: hidden; 
    opacity: 0;
    position: relative;
    object-fit: cover;
    object-position: 50% 60%;
    filter: brightness(0.9);
  }
  
  .slide {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    animation: pan-image 50s infinite alternate;
  }
  
  
  .slide.active {
    opacity: 1;
  }
  
  .slide.fading {
    opacity: 0;
  }
  
  .slide.behind {
    opacity: 1;
  } 
  
  .kv-overlay {
    position: absolute;
    width: 100%;
    height: 580px;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 2.26%, rgba(0, 0, 0, 0.09) 56.98%, rgba(0, 0, 0, 0.00) 102.41%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .kv-arrow {
    position: absolute;
    width: 21px;
    height: 42px;
    top: 45%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px; 
    color: white;
    z-index: 100;
    filter: brightness(0) invert(1);
  }
  
  .kv-arrow-img {
    width: 21px;
    height: 42px;
  }
  
  .arrow-left {
    left: 20px;
  }
  
  .arrow-right {
    right: 20px;
  }
  
  .kv-text {
    position: relative;
    top: 45%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%,-50%);
    letter-spacing: 9.8px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.65);
    color: $onsen-white;
  
    @keyframes fade-in-text {
      0% {
        -webkit-mask-size: 0%;
        -webkit-mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 70%,
          rgba(0, 0, 0, 0)
        );
      }
      100% {
        -webkit-mask-size: 100%;
        -webkit-mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 100%,
          rgba(0, 0, 0, 0)
        );
      }
    }
    
    h2 {
      @include pjt_h2;

      overflow: hidden; 
      white-space: nowrap;
      margin: 0 auto; 
      letter-spacing: 9.8px;
      position: relative;
      animation: fade-in-text 1.5s ease-in;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
    }
  }
  
  .kv-yado-name {
    z-index: 100;
    position: absolute;
    right: 5%;
    bottom: 20%;
    color: $onsen-white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: $pjt-font-s;
    padding: 7px 20px;
    background: $onsen-black80;
  }
}

.kv-search-wrapper {
  position: relative;
  top: -80px;
  height: 160px;
  max-width: 1160px;
  width: 100%;
  margin: 0 auto -80px auto;
  background: $onsen-white;
  padding: 45px 50px;
  z-index: 1;
}

@media screen and (max-width: $breakpoint-md-max) {
  .top-kv-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 370px;
    min-height: 370px;

    .kv-overlay {
      height: 370px;
    }

    .kv-text {
      top: 47%;
      h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 5px;
      }
    }
  
    .kv-arrow {
      display: none;
    }
  }
  .kv-search-wrapper {
    position: relative;
    height: 109px;
    top: -50px;
    width: 100%;
    background: $onsen-white;
    padding: 0;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .kv-search-wrapper {
    height: 300px;
    width: 95%;
    top: -90px;
  }

  .top-kv-wrapper {
    .kv-text {
      top: 35%;
    }
    .kv-yado-name {
      bottom: 30%;
    }
  }
}
