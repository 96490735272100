@import '../../variables.scss';

.filter-summary-wrapper {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
}

.filter-summary-text {
  font-size: $pjt-font-xs;
  margin-right: 10px;
  line-height: 28px;
}

.filter-condition-item {
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $onsen-gray-border;
  font-size: $pjt-font-xs;
  letter-spacing: 1.03px;
}

.filter-condition-show {
  width: 100%;
}

.filter-condition-show--disabled {
  color: $onsen-gray-400;
}

.filter-condition-count {
  right: 0;
  color: $dark-gray-text-color;
}

.filter-condition-accordion {
  .arrow-toggle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.limited-plan {
      .arrow {
        border: solid $onsen-white;
        border-width: 0 2px 2px 0;
      }
    }

    .arrow {
      border: solid $pjt-border;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;

      &.arrow--up {
        transform: translate(0px, 2px) rotate(-135deg);
      }

      &.arrow--down {
        transform: translate(0px, -2px) rotate(45deg);
      }
    }
  }
}

.filter-exclusive-plan {
  &.filter-condition-wrapper {
    border: 1px solid $pjt-limited-yellow;
  }

  .exclusive-plan-title {
    color: $onsen-white;
    background-color: $pjt-limited-yellow;
    font-size: $pjt-font-s;
    font-weight: 700;
    height: 36px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
  }
}

@include min-screen($breakpoint-sm-min) {
  .filter-condition-item {
    height: 35px;
    border-bottom: none;
    font-size: $pjt-font-m;
    letter-spacing: 0.96px;
  }
}
