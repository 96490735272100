.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}
