@import 'variables';

#OnsenYadoOrderCreate,
#OnsenYadoOrderPayment,
#OnsenYadoOrderComplete,
.credit-card-register-modal-content {
  @import 'onsen_yado_common';
  background-color: $onsen-background;
  .bottom-line {
    border-bottom: 1px solid #eeeeee;
  }
  .text-input {
    background: #ffffff;
    border: 1px solid $form-input-gray-border;
    border-radius: 4px;
    width: 100%;
    height: 38px;
    line-height: normal;
    padding: 0.5rem;
    font-size: 16px;

    &.coupon-text-input {
      border-radius: 4px 0 0 4px;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
  .text-input:placeholder-shown {
    color: #b3b3b3;
  }
  .btn-payment {
    background-image: linear-gradient(-225deg, #fb6dad 0%, #f53b75 100%);
    border-radius: 24px;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    text-align: center;
    padding: 12px 60px;
    width: auto;
  }
  .btn-payment:disabled {
    background-image: none;
    background-color: #b3b3b3;
  }
  @include max-screen($breakpoint-sm-max) {
    .price-button-container {
      justify-content: center;
    }
    .btn-payment {
      padding: 12px 30px;
      margin: 10px;
      width: 80%;
    }
  }
}
