@import 'variables';

#OnsenYadoShow,
.contents-modal-container,
.adult-input-dialog-content,
.child-input-dialog-content
{
  @import 'onsen_yado_common';
  background-color: $onsen-background;
  .free-cancellation {
    height: 24px;
    background-image: linear-gradient(-53deg, #57B857 0%, #76BF1F 100%, #76BF1F 100%);
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-align: center;
    line-height: 24px;
  }
  @include max-screen($breakpoint-xs-max) {
    .main-contents {
      padding: 0!important;
    }
  }
  .tax-included-small {
    font-size: 8px;
    color: $onsen-gray-800;
    letter-spacing: 0;
    line-height: 1;
  }
}

// リニューアルでレビューの背景が変わる
#OnsenYadoShow {
  background-color: #FFFFFF;
}

// リニューアルでちょっと広げます
@include min-screen($breakpoint-lg-max) {
  #OnsenYadoShow .container {
    max-width: 1235px;
  }
}
