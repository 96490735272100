.country-top {
  color: #232323;
  font-size: 14px;
  font-family: '\6E38\30B4\30B7\30C3\30AF\4F53', 'Yu Gothic', YuGothic,
    '\30D2\30E9\30AE\30CE\89D2\30B4 Pro', 'Hiragino Kaku Gothic Pro', '\30E1\30A4\30EA\30AA',
    'Meiryo', sans-serif;
}

.img-responsive {
  display: block;
  max-width: 100%;
  border: 0;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

.wa-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    float: left;
    margin: 0 10px;

    a {
      padding: 10px;
      display: block;
      color: #ee4486;
      font-weight: 300;
      font-size: 1.1em;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      &:hover {
        color: #ee4486;
        text-decoration: none;
        border-bottom: 2px solid #ee4486;
        transition: border-color 0.5s;
      }
    }
  }
}

ul.wa-nav-b {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    float: left;
  }
  a {
    white-space: nowrap;
    display: block;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    border-radius: 4;
    transition: all ease-in 0.2s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.wa-section-title {
  color: #ee4486;
  margin: 60px auto 60px;
  padding: 0 10px;
  position: relative;
  font-size: 2.4em;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.3em;

  &::after {
    width: 100px;
    content: '';
    border-bottom: 1px solid #ee4486;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.wa-section-title--sub {
  color: #ee4486;
  font-size: 1.3em;
  font-weight: lighter;
  line-height: 1.4em;
  text-align: center;
  margin: -20px auto 60px;
  padding: 0 10px;
}
.wa-feature {
  padding-bottom: 20px;
  background-color: #fff;
  font-size: 1.1em;
  line-height: 1.55em;
  font-weight: 300;
}
.wa-feature.wa-feature--gray {
  background-color: #f7f7f7;
}
.wa-feature ul {
  padding: 0;
  margin: 0 20px 0 0;
  li {
    line-height: 1.1em;
    padding: 3px 0;
    font-size: 0.9em;
  }
}

.wa-hero {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-top: 1px solid #ee4486;
  border-bottom: 1px solid #ee4486;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 40px;
}
.wa-hero__bg_image {
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  width: 100%;
}
.wa-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wa-hero__logo {
  display: block;
  margin: 0 auto;
  width: 60%;
}

.wa-cta {
  width: 100%;
  height: 500px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wa-cta__link-container {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.wa-cta__link {
  transition: all ease-in 0.2s;

  &.is-aftee {
    img {
      height: 68px;
    }
  }
  img {
    padding: 5px;
    height: 56px;
  }
  &:hover {
    opacity: 0.5;
  }
}

.wa-feature-item {
  display: block;
  margin: 25px auto 20px;
  padding: 10px;
  img {
    width: 160px;
    margin: 0 auto 20px;
  }
}

@media only screen and (min-width: 768px) {
  *[data-reaction='collapse'] {
    display: block;
  }

  .lead {
    font-size: 21px;
  }
  .wa-feature-item img {
    width: 228px;
    margin: 20px auto;
  }

  .wa-hero {
    height: 480px;
  }
  .wa-hero__logo {
    width: 500px;
  }
  .wa-feature {
    margin: 40px 0;
  }
  ul.wa-nav-b {
    top: 10px;
    a {
      padding: 10px 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .lead {
    font-weight: 400;
  }
}

@media only screen and (max-width: 480px) {
  .wa-feature {
    padding-bottom: 40px;
  }
  ul.wa-nav-b {
    width: calc(100% - 20px);
    overflow-x: scroll;
    display: flex;
  }
}
