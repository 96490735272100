@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9u45tj');
  src:  url('fonts/icomoon.eot?9u45tj#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9u45tj') format('truetype'),
    url('fonts/icomoon.woff?9u45tj') format('woff'),
    url('fonts/icomoon.svg?9u45tj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caret-right-gray:before {
  content: "\e907";
  color: #82878e;
}
.icon-caret-down-gray:before {
  content: "\e90e";
  color: #82878e;
}
.icon-caret-up-gray:before {
  content: "\e920";
  color: #82878e;
}
.icon-sim:before {
  content: "\e926";
}
.icon-sim-lg:before {
  content: "\e927";
}
.icon-app-lg:before {
  content: "\e928";
}
.icon-app:before {
  content: "\e925";
}
.icon-insurance-lg:before {
  content: "\e91e";
}
.icon-insurance:before {
  content: "\e91f";
}
.icon-caret-down:before {
  content: "\e91a";
}
.icon-caret-right:before {
  content: "\e904";
}
.icon-wamazing-filled:before {
  content: "\e917";
}
.icon-left-arrow:before {
  content: "\e916";
}
.icon-right-arrow:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e900";
}
.icon-map-filled:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-cart:before {
  content: "\e903";
}
.icon-map:before {
  content: "\e905";
}
.icon-play:before {
  content: "\e906";
}
.icon-transport-lg:before {
  content: "\e908";
}
.icon-shop-lg:before {
  content: "\e909";
}
.icon-discover-lg:before {
  content: "\e90a";
}
.icon-stay-lg:before {
  content: "\e90b";
}
.icon-play-lg:before {
  content: "\e90c";
}
.icon-snow-lg:before {
  content: "\e90d";
}
.icon-discover:before {
  content: "\e910";
}
.icon-snow:before {
  content: "\e911";
}
.icon-shop:before {
  content: "\e912";
}
.icon-stay:before {
  content: "\e913";
}
.icon-transport:before {
  content: "\e914";
}
.icon-hot_tub:before {
  content: "\e91b";
}
.icon-directions_bus:before {
  content: "\e91c";
}
.icon-restaurant:before {
  content: "\e91d";
}
.icon-whatsapp:before {
  content: "\e921";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-facebook-f:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e918";
}
.icon-wechat:before {
  content: "\e919";
}
.icon-weixin:before {
  content: "\e919";
}
