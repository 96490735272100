.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 15px;
  width: 100%;
  margin: 0;
  cursor: pointer;

  &.auto-width {
    width: auto;
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background: $onsen-white;
    border: solid 1px $pjt-border;
    width: 15px;
    height: 15px;
  }

  .checkmark--disabled {
    background: $onsen-checkbox-disabled-background;
    border: solid 1px $gray300;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 4px;
      top: 0;
      width: 5px;
      height: 10px;
      border: solid $dark-gray-text-color;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
