.support-kv {
  position: relative;
  width: 1180px;
  height: 268px;

  .support-kv-pc {
    display: block;
  }

  .support-kv-sp {
    display: none;
  }

  .kv-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: $onsen-white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35)) drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.55));

    h1 {
      font-size: 40px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .kv-line-pc {
    display: block;
    position: absolute;
    bottom: 12px;
    right: 0;
    width: 250px;
    height: 140px;

    .kv-line-content{
      position: relative;

      .kv-illust {
        position: absolute;
        right: 22px;
        width: 234px;
        height: 140px;
      }
    }
  }

  .kv-line-sp {
    display: none;
  }
}

.support-introduction {
  display: flex;
  justify-content: space-between;

  .introduction-text {
    white-space: pre;
  }

  .introduction-image {
    position: relative;
    width: 506px;
    height: 310px;
  }

  .intro-illust {
    position: absolute;
    bottom: 0;
    right: -32px;
    width: 96px;
    height: 229px;
  }
}

.support-examples {
  display: flex;
  flex-direction: column;
  gap: 45px;

  .example-question {
    display: flex;
    gap: 11px;
    justify-content: left;
    margin-bottom: 25px;

    .example-question-text {
      position: relative;
      display: flex;
      height: 52px;
      margin-top: 10px;
      padding: 15px 20px 10px 20px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      background-color: $support-green-light;

      &::before {
        content: "";         
        position: absolute;
        top: 50%;            
        left: -11px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;     
        border-bottom: 10px solid transparent;
        border-right: 18px solid $support-green-light;   
        transform: translateY(-50%);    
      }
    }
  }

  .example-question-image {
    width: 71px;
    height: 71px;
  }

  .example-answers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 85px;
    .example-answer-details {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    .example-answer-detail {
      display: flex;
      width: 31%;
      height: 120px;
      padding: 10px 15px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      background-color: $onsen-background;
    }
  }
}

.support-samples {
  display: flex;
  justify-content: center;
  background-color: $onsen-background;
  padding: 20px;
  gap: 20px;

  .sample-question {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    h3 {
      font-size: $pjt-font-m;
    }
  }

  .sample-chat {
    width: 375px;
    
    &__1 {
      height: 402px;
    }

    &__2 {
      height: 474px;
    }
  }
}

.support-features {
  display: flex; 
  justify-content: space-evenly;
  gap: 20px;

  .support-feature {
    display: flex;
    gap: 18px;
    width: 33%;
  }

  .support-feature-text{
    margin-top: -3px;
    white-space: pre-wrap;
    h3{
      line-height: 190%;
    }
  }
}

.support-search-link {
  &:hover {
    text-decoration: none;
  }
}

.support-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 55px;
  margin: 63px auto 0 auto ;
  background-color: $onsen-white;
  border-radius: 3px;
  border: 1px solid $pjt-blue;
  color: $pjt-blue;
  transition: all 0.15s ease-in;

  &:hover {
    text-decoration: none;
    background-color: $pjt-blue;
    color: $onsen-white;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {

 .support-kv {
  width: 100%;
  height: 200px;

  .support-kv-pc {
    display: none;
  }

  .support-kv-sp {
    display: block;
  }


  .kv-text {
    width: 100%;
    top: 40%;

    h1 {
     font-size: 30px;
    }

    h3{
      font-size: 14px;
    }
  }

  .kv-line-pc {
    display: none;
  }

  .kv-line-sp {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 20px;
    justify-content: center;

    .kv-illust {
      width: 174px;
      height: 50px; 
    }

  }
 }

 .support-content-sp {
  padding: 0 10px;
 }

 .support-introduction {
  width: 100%;
  display: block;

  p {
    white-space: pre-wrap;
  }

  .introduction-image {
    width: 100%;
    height: 218px;
  }

  .intro-illust {
    top: 2px;
    right: 2px;
  }
 }

 .support-examples {
  .example-question {
  .example-question-text {
    height: auto;
    margin-top: -7px;
    h3 {
    font-size: 17px;
    }

  }


  }
  .example-answers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 0;
    .example-answer-details {
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
    }

    .example-answer-detail {
      display: flex;
      width: 100%;
      height: auto;
    }
  }
 }

 .support-samples {
  flex-direction: column;

  .sample-chat {
    width: 100%;

    &__1 {
      height: 338px;
    }

    &__2 {
      height: 398px;
    }
  }
 }

 .support-features {
  flex-direction: column;

  .support-feature {
    width: 100%;
  }
  
  .support-feature-text{
    margin-top: -3px;

    h3{
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 190%;
    }
  }
 }
}

.support-banner {
  position: relative;
  width: 564px;
  height: 266px;
  cursor: pointer;
  @include top_hover;

}
.support-banner-pc {
  display: block;
}
.support-banner-sp {
  display: none;
}
@media screen and (max-width: $breakpoint-xs-max) {
  .support-banner {
    width: 100%;
    height: 62vw;
    padding: 0 12px;
  }
  .support-banner-pc {
    display: none;
  }
  .support-banner-sp {
    display: block;
  }
}

.support-intro-link {
  color: $pjt-blue;

  .link-text {
    margin-right: 5px;
    text-decoration: underline;
  }

  
}

.support-bar {
  position: relative;
  width: 100%;
  background: linear-gradient(115deg, #30C44C 33.74%, #25A73D 84.22%);
  text-align: center;
  color: $onsen-white;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  @include top_hover(0.9);
  
  a {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }

  .support-bar-sp {
    display: none;
  }

  .support-bar-pc {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
  }

  .support-name {
    font-size: 28px;
    font-weight: 700;
    margin-right: 10px;
  }

  .support-bar-illust {
    position: absolute;
    max-width: 91px;
    max-height: 54px;
    left: -25px;
  }

  .support-chevron {
    display: inline;
    max-width: 20px;
    margin-left: 5px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .support-bar {
    text-align: left;

    .support-bar-sp{
      position: relative;
      display: block;
      padding-left: 20px;
      height: 47px;

      .support-name {
        font-size: 18px;
        font-weight: 700;
      }

      .support-slogan {
        display: flex;
        align-items: center;
        margin-top: -2px;
        font-size: 12px;
      }
    
      .support-bar-illust {
        position: absolute;
        max-width: 91px;
        max-height: 54px;
        top: 4px;
        left: 75%;
      }
    
      .support-chevron {
        display: inline;
        max-width: 20px;
        margin-left: 5px;
      }
    }
    .support-bar-pc {
      display: none;
    }
  }
}