.activity-carousel {
  .carousel-container {
    .card-wrapper {
      .card-content-wrapper {
        min-height: 134px;
        justify-content: space-between;

        .activity-name {
          display: -webkit-box;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.4;
        }
      }
    }
  }
}
