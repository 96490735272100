.about-banner-wrapper {
  max-width: 1160px;
  width: 100%;
  margin: 34px auto;
  text-align: center;
}

.about-banner {
  display: flex;
  justify-content: center;
  gap: 33px;
  margin: 26px 0;
}

.about-banner-img {
  max-width: 411px;
  max-height: 205px;
}

.about-banner-text {
  max-width: 260px;
  white-space: pre;
  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
  ul {
    margin-top: 12px;
  }
  li {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 7px;
  }
}

.about-banner-button {
  min-width: 320px;
  height: 55px;
  padding: 9px 20px;
  gap: 10px;
  border-radius: 3px;
  background-color: $onsen-blue;
  color: $onsen-white;
  border: none;
}

@media screen and (max-width: $breakpoint-md-max) {
  .about-banner-wrapper {
    width: 95%;
    margin: 34px auto;
    text-align: center;
    
    h1 {
      font-size: 24px;
      margin-bottom: 28px;
    }

    h3{
      font-size: 17px;
    }
  }
  
  .about-banner {
    display: block;
    justify-content: center;
    gap: 33px;
    max-width: 411px;
    margin: 26px auto;
  }

  .about-banner-img {
    width: 100%;
    height: auto;
    min-height: 165px;
  }
  
  .about-banner-text {
    width: 80%;
    margin: 28px auto 0 20px;
    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      text-align: left;
    }
    ul {
      margin-top: 12px;
    }
    li {
      text-align: left;
      margin-left: 20px;
      margin-bottom: 7px;
    }
  }
  
  .about-banner-button {
    width: 100%;
    max-width: 411px;
    height: 55px;
    padding: 0 20px;
    margin-top: 4px;
    gap: 10px;
    border-radius: 3px;
    background-color: $onsen-blue;
    color: $onsen-white;
    border: none;
  }
  
}