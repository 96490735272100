@import 'variables';

/* General */

.thin-box {
  background-color: $onsen-light-blue;
  padding: 8px;
  &__inner {
    background-color: $onsen-white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: calc($onsen-radius-sm / 2);
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.onsen-btn {
  background-color: $onsen-white;
  border: 1px solid #2187f2;
  color: #2187f2;
  font-size: 0.9rem;
  padding: 0.5rem 2rem;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    background-color: lighten(#2187f2, 43);
    color: darken(#2187f2, 10);
  }
  &.sm {
    border-radius: calc($onsen-radius-sm / 2);
    font-size: 0.8rem;
    padding: 0.25rem 1rem;
  }
  &.pink {
    background-color: $onsen-white;
    border-radius: calc($onsen-radius-sm / 2);
    border: 1px solid $onsen-pink;
    color: $onsen-pink;
    &:hover {
      background-color: lighten($onsen-pink, 45);
      color: $onsen-pink;
    }
  }
}

.OnsenOrderSummary {
  @import 'onsen_yado_common';
  padding: 1rem;
  section {
    border-bottom: 1px solid $onsen-gray-200;
    padding-bottom: 1rem;
    .checkin-out {
      font-size: 0.8em;
      font-weight: bold;
      line-height: 1.6em;
      margin-bottom: 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  .yado-thumbnail {
    width: 72px;
    height: 72px;
    margin-right: 1rem;
    border-radius: $onsen-radius-sm;
    background-color: $onsen-gray-200;
  }
  .dinner-thumbnail {
    width: 56px;
    height: 56px;
    margin-right: 1rem;
    border-radius: 50%;
    background-color: $onsen-gray-200;
  }
}

/* Pages */

#OnsenYadoBookings,
#OnsenYadoBookingDetail,
#OnsenYadoBookingDetailPickupRequest {
  @import 'onsen_yado_common';
  background-color: $onsen-gray-100;

  .booking {
    padding: 0;
  }
  .booking__yado-address {
    font-size: 0.8rem;
    color: $dark-gray-text-color;
  }
  .booking__divider {
    margin: 1rem 0;
    border: 0;
    border-bottom: 1px solid $onsen-gray-200;
  }
  .booking__section {
    h6 {
      color: #2187f2;
    }
  }
  .booking__pickup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $onsen-gray-100;
    border: 1px solid $onsen-gray-200;
    padding: 0.5rem 1rem;
    border-radius: $onsen-radius-sm;
  }
  .booking__pickup__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $onsen-black;
    background-color: $onsen-white;
    margin-right: 0.5rem;
  }
}
