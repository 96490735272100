.service-introduction {
  background-color: $onsen-white;

  h1 {
    @include local_h1
  }

  h2 {
    @include local_h2
  }

  h3 {
    @include local_h3
  }

  h4 {
    @include local_h4
  }

  p {
    @include intro_p
  }

  .p_bold {
    @include intro_p_bold;
    font-weight: bold;
  }
}

.service-header {
  position: relative;
  object-fit: cover;
}

.header-onsen {
  object-position: 0 75%;
}

.service-header-img {
  position: relative;
  width: 100%;
  height: 316px;
  object-fit: cover;
}

.service-header-content {
  position: absolute;
  top: 0;
  left: 50%;
  color: $onsen-white;
  transform: translateX(-50%);
  font-size: 50px;
  padding-top: 70px;
  width: 100%;
  height: 316px;
  text-align: center;
  background-color: $onsen-black80;

  h1 {
    font-size: 45px;
    margin-top: -10px;
  }
}

.service-wamazing-wrapper {
  width: 85%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 20px;
}

.service-icon-wrapper {
  width: 62px;
  height: 62px;
  background-color: $onsen-white;
}

.service-icon {
  width: 36px;
}

.yado-signature {
  font-size: 14px;
  margin: 10px 0;
  font-weight: bold;
}

.service-explanation {
  padding-top: 85px;
  text-align: center;
}

.service-explanation-headers {
  width: 40%;
  margin: 0 auto;
}

.service-desc {
  margin: 70px 0 80px 0;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.service-desc-img {
  width: 100%;
  max-width: 699px;
  aspect-ratio: 700/465;
}

.service-desc-txt {
  width: 400px;
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: 40px;
  white-space: pre-line;

  @include intro_p_bold
}

.service-wamazing-requests {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-top: 8px;
}

.service-requests {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 60px;
  gap: 55px;
}

.service-request {
  width: 400px;
}

.service-request-img {
  max-width: 255px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.service-request-txt {
  margin-top: 35px;
  text-align: left;
}

.service-wamazing-txt {
  margin-top: 10px;
  text-align: left;
}

.service-wamazing {
  position: relative;
  width: 100%;
  min-height: 300px;
  background-color: $onsen-background;
  padding: 76px 0 20px 0;

  &::before {
   content: "";
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%); 
   width: 0;
   height: 0;
   border-left: 200px solid transparent;
   border-right: 200px solid transparent;
   border-top: 48px solid white;
  }
}

.service-wamazing-detail {
  flex: 1;
  text-align: center;
  position: relative;
  background-color: $onsen-white;
  max-width: 440px;
  padding: 0 20px 5px 20px;
  border: solid 1px $onsen-gray-300;
}

.service-wamazing-index {
  position: relative;
  top: -30px;
  font-size: 26px;
  width: 45px;
  height: 44px;
  margin: 0 auto;
  background-color: $onsen-gray-fill;
  border-radius: 50%;
  color: $onsen-white;
  margin-bottom: -20px;
  padding-top: 3px;
}

.service-wamazing-img {
  width: 100%;
  aspect-ratio: 5/3;
  height: 200px;
}

@media screen and (max-width: $breakpoint-md-max) {
  .service-introduction {
    min-height: 100px;
    background-color: $onsen-white;
    padding-bottom: 0px;
  }
  
  .service-header {
    position: relative;
    width: 100%;
    object-fit: cover;
    min-height: 0;

    h1 {
      margin-top: 5px;
    }
  }

  .header-onsen {
    object-position: 0 0;
  }
  
  .service-header-img {
    height: 228px;
  }
  
  .service-header-content {
    position: absolute;
    top: 0;
    left: 50%;
    color: $onsen-white;
    transform: translateX(-50%);
    padding-top: 20px;
    font-size: 45px;
    text-align: center;
    width: 100%;
    height: 228px;
    font-weight: bold;

    h1 {
      font-family: serif;
      font-weight: 900;
      font-size: 35px;
      line-height: 40px;
    }
  }
  
  .service-icon-wrapper {
    width: 62px;
    height: 62px;
    margin: 10px auto;
  }
  
  .service-icon {
    width: 42px;
    height: 42px;
  }
  
  .yado-signature {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .service-explanation {
    padding: 60px 0 0px 0;
    text-align: center;
  }

  .service-explanation-headers {
    width: 90%;
    margin: 0 auto;
  }

  .service-wamazing-wrapper {
    width: 100%;
    margin: 65px auto 0 auto; ;
  }
  
  
  .service-desc {
    padding-left: 0;
    margin: 45px 0;
    display: block;
    justify-content: center;
    gap: 20px;
  }
  
  .service-desc-img {
    width: 100%;
    padding: 10px;
    height: auto;
    object-fit: cover;
  }
  
  .service-desc-txt {
    width: 100%;
    padding: 20px;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    align-items: center;
    text-align: left;
    margin-right: 40px;
    white-space: pre-line;
  }

  .service-wamazing-requests {
    display: block;
    justify-content: center;
    gap: 32px;
  }
  
  .service-requests {
    display: block;
    justify-content: center;
    gap: 20px;
  }
  
  .service-request {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .service-request-img {
    width: 255px;
    height: 255px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .service-request-txt {
    margin: 20px 0;
    text-align: left;
    line-height: 36px;
  }

  .service-questions-txt {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }
  
  .service-wamazing {
    width: 100%;
    min-height: 300px;
    background-color: $onsen-background;
    padding: 60px 10px 0 10px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); 
      width: 0;
      height: 0;
      border-left: 45vw solid transparent;
      border-right: 45vw solid transparent;
      border-top: 63px solid white;
     }
  }
  
  .service-wamazing-detail {
    text-align: center;
    position: relative;
    width: 100%;
    margin: 0 auto;
    aspect-ratio: auto;
    min-width: 0;
    background-color: $onsen-white;
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
  }
  
  .service-wamazing-index {
    position: relative;
    top: -40px;
    font-size: 26px;
    width: 45px;
    height: 45px;
    margin: 0 auto;
    background-color: $onsen-gray-800;
    border-radius: 50%;
    color: $onsen-white;
    margin-bottom: -20px;
    padding-top: 2px;
  }
  
  .service-wamazing-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: auto;
    min-width: 0;
  }
}