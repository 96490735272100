.common-input-label {
  width: 100%;
  display: flex;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  p {
    font-size: 12px;
    font-weight: 500;
    color: #545454;
    width: 50%;
    margin-left: 0px;
    white-space: nowrap;

    &.last-label {
      padding-left: 20px;
    }
  }
}

.guest-input-wrapper {
  position: relative;
}

@media screen and (max-width: $breakpoint-sm-max) {
  .common-input-label {
    p {
      font-weight: 700;
    }
  }
}