@import 'variables';

#OnsenGlobalHeader {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $onsen-white;
  .brand {
    display: flex;
    align-items: center;
  }
  .brand__logo {
    height: 24px;
  }
  .brand__caption {
    font-size: 12px;
    color: $onsen-gray-800;
    white-space: nowrap;
  }
  .navs {
    display: flex;
  }
  .navs__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $onsen-gray-100;
    border-radius: 20px;
    margin-left: 16px;
  }
}

#OnsenTop {
  .onsen-hero {
    position: relative;
    z-index: -1;
  }
  .onsen-hero-bg {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    clip-path: ellipse(140% 55% at 50% 42%);
    z-index: -1;
  }
  .onsen-hero-inner {
    position: absolute;
    width: 360px;
    img {
      width: 100%;
    }
    right: 10%;
    top: 22%;
    z-index: 3;
  }
  .dinner-list {
    overflow-x: hidden;
    margin-top: -40px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  @include max-screen($breakpoint-sm-max) {
    .onsen-hero-bg {
      height: 200px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(255,255,255,0.0);
        z-index: -1
      }
    }
    .onsen-hero-inner {
      width: 80%;
      left: 10%;
      top: auto;
      bottom: -52px;
    }
    .dinner-list {
      margin-top: 32px;
      overflow-x: scroll;
      .active {
        margin-top: 0!important;
      }
    }
  }

  .onsen-feature-wrapper {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onsen-feature {
    padding: 5rem 1rem;
    img {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    h6 {
      font-size: 14px;
      margin: 0.5rem 0;
      text-align: center;
    }
    p {
      font-size: 12px;
      color: $onsen-gray-800;
      margin-bottom: 0;
    }
  }
  @include max-screen($breakpoint-sm-max) {
    .onsen-feature {
      padding: 1.3rem 5rem 0 5rem;
      h6 {
        margin: 0.5rem -1.5rem;
      }
      p {
        margin: 0 -1rem;
      }
    }
  }
}

.DinnerCard {
  width: 280px;
  padding: 1rem;
  background-color: $onsen-white;
  border-radius: $onsen-radius-sm;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  z-index: 2;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &.active, &:hover {
    margin-top: -32px;
  }
  .thumbnail {
    position: relative;
    &__photo {
      border-radius: $onsen-radius;
      border-bottom-right-radius: $onsen-radius*4;
      height: 160px;
      background-color: $onsen-gray-300;
    }
    &__rating {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: $onsen-white;
      font-size: 12px;
      color: $onsen-yellow;
      text-align: center;
      line-height: 16px;
      padding: 3px 0;
      strong {
        display: block;
        text-align: center;
        color: $onsen-gray-800;
      }
    }
  }
  .dinner-name {
    font-size: 14px;
    letter-spacing: 1px;
    color: $onsen-black;
    text-align: center;
    margin: 1.5rem 0 1.25rem;
  }
  .caption {
    font-size: 10px;
    color: $onsen-gray-800;
    line-height: 1.6em;
  }
  .plan-room {
    font-size: 10px;
    line-height: 1.6em;
    font-weight: bold;
  }
  .plan-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .plan-price__local {
    color: $onsen-red;
    font-size: 12px;
    strong {
      font-size: 20px;
    }
  }
  .plan-price__jpy {
    color: $onsen-gray-400;
    font-size: 12px;
    font-weight: bold;
  }

}
