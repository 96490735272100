.search-yados {
  position: relative;
  max-width: 1140px;
  width: 80%;
  margin: 75px auto;
  
  .search-yados-img {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .search-yados-button {
    position: absolute;
    width: 85%;
    min-height: 140px;
    height: auto;
    transform-origin: center;
    top: calc(50% - 70px);
    left: 50%;
    transform: translateX(-50%);
    border: solid 1px $onsen-white;
    font-size: 32px;
    color: $onsen-white;
    background-color: $onsen-white20;
    border-radius: 5px;
  }
  .search-yados-button:hover {
    background-color: $onsen-black80;
  }

  .search-yados-arrow {
    color: $onsen-white;
    margin-left: 10px;
    padding-bottom: 4px;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .search-yados {
    position: relative;
    width: 90%;
    margin: 40px auto;
    height: auto;
    min-height: 190px;
    object-fit: cover;

    .search-yados-img {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 190px;
      margin: 0 auto;
      object-fit: cover;
    }
    .search-yados-button {
      position: absolute;
      width: 260px;
      height: auto;
      min-height: 80px;
      transform-origin: center;
      top: calc(50% - 40px);
      border: solid 1px $onsen-white;
      font-size: 18px;
      color: $onsen-white;
      background-color: $onsen-white20;
    }
  }
}