.pjt-container {
  max-width: 1230px;
  width: 100%;
  margin: 10px auto;
  padding: 0 10px;

  .MuiTabs-flexContainer {
    font-weight: 400;
    border: 1px solid $pjt-border;
    border-radius: 5px;
    width: 100%;

    button {
      border-right: 1px solid $pjt-border;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiPaper-root {
    &.MuiPaper-elevation4 {
      box-shadow: none;
    }
  }

  .MuiBox-root {
    padding: 0;
  }

  .MuiTab-wrapper {
    font-size: 13px;
  }

  .btn-secondary--no-border {
    color: $pjt-blue;
    font-weight: 500;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }
}

.global-support-intro {
  max-width: 564px;
  margin: 40px auto;
}

.wa-card-support-intro {
  width: 100%;
  margin: 20px auto;
}

.wa-card-wrapper {
  padding: 20px 0;
  border-top: 1px solid $pjt-border;

  .wa-card-container {
    max-width: 1160px;
    margin: 0 auto;
    display: block;
    gap: 20px;

    .wa-card {
      text-align: center;
      width: 100%;

      .wa-card-image {
        width: 100%;
        aspect-ratio: 2/1;
        object-fit: cover;
      }

      .wa-card-content {
        padding: 0 10px;
        text-align: start;
        margin: 30px 0 20px 0;

        h3 {
          min-height: 32px;
          display: flex;
          align-items: flex-end;

          .wa-logo {
            height: 30px;
          }
        }

        .wa-ul {
          font-size: $pjt-font-m;
          padding-left: 5px;
          line-height: 180%;

          li {
            list-style-position: inside;
          }
        }
      }
    }
  }

  .wa-card-btn {
    margin: 25px 0 40px 0;
    height: 55px;
    width: 80%;
    color: $dark-gray-text-color;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $onsen-white;
    border: 1px solid $pjt-border;
    font-size: $pjt-font-m;
    align-items: center;
    justify-content: center;
  }
}

.basic-info-container {
  h2 {
    @include pjt_h2;
  }

  h3 {
    @include pjt_h3;
  }

  h4 {
    @include pjt_h4;
  }

  .pjt-link {
    a,
    .map-link-wrapper {
      color: $pjt-blue;
      text-decoration: underline;
    }
  }

  .pjt-underline-btn,
  .modal-open-btn {
    appearance: none;
    border: none;
    background: none;
    color: $pjt-blue;
    font-size: $pjt-font-s;
    padding: 0;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }
}

.pjt-font-l {
  font-size: $pjt-font-l !important;
}

.see-more-arrow {
  margin-left: 5px;
  padding-bottom: 4px;
}

@include mobile {
  .pjt-container {
    .MuiTab-wrapper {
      font-size: 14px;
    }

    .MuiPaper-root {
      padding: 0;
    }
  }

  .wa-card-support-intro {
    width: 564px;
    margin: 70px auto 60px auto;
  }

  .wa-card-wrapper {
    padding: 60px 0;

    .wa-card-container {
      display: flex;
      justify-content: space-between;

      .wa-card {
        max-width: 560px;
      }
    }

    .wa-card-btn {
      width: 200px;
    }
  }
}

@include ipad {
  .wa-card-wrapper {
    .wa-card-btn {
      width: 320px;
    }
  }
}

@include desktop {
  .pjt-container {
    padding: 0;
  }
}
