@import './breakpoints.scss';
.top-page-heading {
  font-size: 1.625rem;
  line-height: 1.3;
  font-weight: 400;
  color: #3e99cd;
  background-color: #f1f8fc;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}

.price-table-container {
  background-color: #ffffff;
  padding: 25px 0;

  .price-table {
    width: 100%;
    margin-bottom: 15px;

    .table-head {
      display: none;
    }

    .price-table-content {
      padding: 7px 10px;
    }

    .price-table-period {
      font-size: 0.9375rem;
      line-height: 1.7;

      align-items: center;
      background-color: #f1f8fc;
      display: flex;
      height: 40px;
    }

    .price-table-price {
      font-weight: bold;
      padding-top: 7px;
    }

    .price-table-details {
      color: #61666e;
    }

    .price-table-period,
    .price-table-price,
    .price-table-details {
      padding-left: 10px;
    }

    .table-content-row {
      border-bottom: 1px solid #59b8ed;
      padding-bottom: 7px;
    }
  }

  & > p {
    padding: 0 10px;
    font-size: 0.875rem;
    line-height: 1.7;
  }
}

@include mobile {
  .price-table-container {

    .price-table {
      .table-head {
        font-size: 0.9375rem;
        line-height: 1.7;
        border-bottom: 2px solid #59b8ed;
        color: #3e99cd;
        display: flex;
        text-align: center;

        .table-period {
          flex: 1;
          max-width: 112px;
        }

        .table-price {
          flex: 2;
        }

        .table-details {
          flex: 3;
        }
      }

      .table-content-row {
        border: none;
        display: flex;
        padding-bottom: 0;

        div {
          align-items: center;
          border-bottom: 1px solid #59b8ed;
          display: flex;
          height: 45px;
          justify-content: center;
        }

        .price-table-period,
        .price-table-price,
        .price-table-details {
          padding-right: 10px;
        }

        .price-table-period {
          flex: 1;
          max-width: 112px;
        }

        .price-table-price {
          padding-top: 0px;
          flex: 2;
        }

        .price-table-details {
          flex: 3;
        }
      }
    }
  }
}

@include tablet {
  .price-table-container {
    padding: 35px 50px;
  }
}
