.search-panel-wrapper {
  width: 100%;
}

.top-wrapper {
  position: relative;
  overflow: hidden;

  .keyword-areas {
    max-width: 1163px;
    margin: 20px auto;

    a {
      color: $onsen-black;
    }
  }

  .simple-areas-wrapper {
    margin: 24px 0 32px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    a:hover {
      text-decoration: none;
    }
  }

  .simple-area {
    width: fit-content;
    padding: 5px 20px;
    align-items: center;
    border-radius: 25px;
    border: solid 1px $pjt-border;
    font-weight: bold;
    transition: border 0.3s;

    &:hover {
      border: 1px solid $pjt-blue;
    }
  }

  .top-feature-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    background-color: $pjt-background-white;
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: $onsen-gray-300;
    margin: 0;
    padding: 0;
  }
  .top-title {
    padding-bottom: 2px;
    width: 100%;
    width: fit-content;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1.33px;
    color: #4a4a4a;
  }
}

.about-header-link {
  display: block;
  font-size: 16px;
  color: $dark-gray-text-color;
  margin-right: 12px;
  padding-top: 5px;

  &:hover {
    color: $dark-gray-text-color;
    text-decoration-color: $dark-gray-text-color;
  }
}

.top-support-intro {
  max-width: 564px;
  margin: 0px auto;
}


@media screen and (max-width: $breakpoint-md-max) {
  .wa-card-wrapper {
    margin: 20px auto;
    padding: 0 10px;
    border-top: none;
  }

  .top-support-intro {
    margin: 50px auto 0;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .keyword-areas {
    margin-bottom: 20px;
    padding: 0 12px;
  }
  .about-header-link {
    display: none;
  }
}

@media screen and (max-width:$breakpoint-xs-max) {
  .top-support-intro {
    margin: 0 auto;
  }
}
