.feature-article-wrapper {
  width: 95%;
  margin: 0 auto 55px;

  .feature-name {
    @include pjt_h2;

    color: $dark-gray-text-color;
    padding-bottom: 13px;
    border-bottom: 1px solid $dark-gray-text-color;
    margin-bottom: 20px;
  }
}

@include desktop {
  .feature-article-wrapper {
    max-width: 1001px;
  }
}
