.common-radio-selector {
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid $pjt-light-gray;
  cursor: default;

  &:hover {
    cursor: pointer;
  }

  &.checked {
    border: 1px solid $pjt-blue;
    background-color: $pjt-background-bluish;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid $pjt-gray;
    outline: none;
  }

  input[type='radio']:hover {
    cursor: pointer;
  }
  
  input[type='radio']:checked {
    border: 1px solid $pjt-blue;
    position: relative;
  }
  
  input[type='radio']:checked::before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $pjt-blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}