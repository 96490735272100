.feature-cards-wrapper {
  display: flex;
  max-width: 1140px;
  margin: 35px auto 0;
  gap: 40px;

  &.view-all {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 65px auto;

    .feature-card {
      display: flex;
      flex-direction: column;
      gap: 15px 0;

      .feature-image-container {
        max-width: 360px;
        margin: 0 auto;
      }

      .feature-contents {
        display: flex;
        flex-direction: column;
        gap: 15px 0;
        margin: 0 auto;
        max-height: 100%;

        .feature-description {
          margin-top: 0;
        }
      }
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .feature-card {
    display: block;
    width: 360px;
    @include top_hover;

    .feature-image-container {
      max-width: 360px;
    }

    .feature-contents {
      width: 360px;
      margin-top: 15px;
      word-break: break-all;
    }

    .feature-title {
      @include pjt_h3;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      max-height: 100px;
      line-height: 40px;
    }

    .feature-description {
      margin-top: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 50px;
    }
  }

  a {
    color: $onsen-black;
  }
}

.see-more-sp {
  display: none;
}

@media screen and (max-width: $breakpoint-lg-max) {
  .feature-cards-wrapper {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
    gap: 15px;
    max-width: 960px;

    &.view-all {
      grid-template-columns: repeat(2, 1fr);
    }

    .feature-card {
      display: flex;
      width: 100%;

      .feature-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 95px;
        margin-top: 0;
        margin-left: 15px;
      }

      .feature-title {
        font-size: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 51px;
        line-height: 25.5px;
      }

      .feature-description {
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 50px;
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-min) {
  .feature-cards-wrapper {
    &.view-all {
      grid-template-columns: 1fr;

      .feature-card {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .feature-cards-wrapper {
    &.view-all {
      width: 100%;
      margin: 30px auto;
    }

    .feature-card {
      .feature-image-container {
        width: 100%;
      }
      .feature-contents {
        max-height: 80px;

        .feature-description {
          margin-top: 5px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
        }
      }
    }
  }

  .see-more-sp {
    display: block;
    text-align: right;
    color: $pjt-blue;

    a {
      color: $pjt-blue;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $breakpoint-xxs-min) {
  .view-all {
    .feature-contents {
      padding: 0 10px;
    }
  }
}
