.top-special-feature {
  display: flex;
  flex-direction: column;
  margin: 35px auto;

  .top-title {
    @include pjt_h2;
    display: inline;
  }

  .see-more {
    display: none;
  }

  .feature-wrapper {
    justify-content: space-around;
  }
}

@include mobile {
  .top-special-feature {
    .see-more {
      display: block;
      float: right;
      margin-top: 18px;
      align-self: flex-end;
      padding-right: 0px;

      a {
        color: $pjt-blue;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          color: $pjt-blue;
        }
      }
    }
  }
}

@include desktop {
  .top-special-feature {
    max-width: 1163px;
    width: 1160px;

    .see-more {
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .top-special-feature {
    margin: 50px auto 20px auto;
    width: 100%;
    padding: 0 12px;
  }
}

// pagination
.feature-pagination {
  display: flex;
  justify-content: center;
  margin: 65px auto;

  .rc-pagination-next,
  .rc-pagination-prev {
    .rc-pagination-item-link {
      padding: 0;
      margin: 0;
      font-size: 18px;
      font-family: '微軟正黑體', 'Microsoft JhengHei', 'Microsoft JhengHei UI', '微軟雅黑',
        'Microsoft YaHei', 'Microsoft YaHei UI', sans-serif;
    }
  }
  .rc-pagination-next .rc-pagination-item-link,
  .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-item {
    border-radius: 4px;
  }

  .rc-pagination-item-active,
  .rc-pagination-item-active a {
    color: $onsen-white;
    background-color: $gray400;
    border-color: transparent;
    border-radius: 4px;
  }

  .rc-pagination-next:focus .rc-pagination-item-link,
  .rc-pagination-next:hover .rc-pagination-item-link,
  .rc-pagination-prev:focus .rc-pagination-item-link,
  .rc-pagination-prev:hover .rc-pagination-item-link,
  .rc-pagination-item:hover {
    border-color: $pjt-border;
    background-color: $pjt-background-bluish;
    color: $dark-gray-text-color;

    &.rc-pagination-item-active:hover {
      color: $onsen-white;
      background-color: $gray400;
    }
  }

  .rc-pagination-disabled .rc-pagination-item-link,
  .rc-pagination-disabled:hover .rc-pagination-item-link {
    background-color: $onsen-gray-border;
    color: $pjt-gray;
    border: none;
  }
}
