@import '../breakpoints.scss';
@import '../variables.scss';

.top-gallery-wrapper {
  max-width: 1194px;
  width: 100%;
  background-color: white;
  position: relative;
  margin: 35px auto 50px;
  z-index: 0;

  .next-image-container {
    background-color: $onsen-white;
  }

  .gallery-button-group {
    margin-top: 15px;
    font-size: 0.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.hide-btn {
      display: none;
    }

    .current-page {
      min-width: 33px;
      padding: 0 5px;
      color: #545454;

      span {
        float: left;
        min-width: 8px;
      }
    }

    button {
      appearance: none;
      border: none;
      background: none;
      outline: none;
    }
  }
  .gallery-image {
    width: 360px;
    @include top_hover;
  }

  .gallery-img-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: -5px;
    border: 1px solid $pjt-gray-fill;
    background-color: #fff;
    :not(:last-child) {
      margin-right: 15px;
    }
    &.gallery-img-dot-active {
      background-color: $pjt-gray-fill;
    }
  }
  .carousel-container {
    position: unset;
  }

  .carousel-arrow-left {
    display: block;
  }

  .carousel-arrow-right {
    display: block;
  }
  .carousel-item {
    display: flex;
    justify-content: center;
  }
  .react-multiple-carousel__arrow {
    background: transparent; 
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 10;
    width: 21px;
    height: 42px;

    &:hover {
      background-color: transparent;
      opacity: 80%;
    }
  }
  
  .react-multiple-carousel__arrow::before {
    color: $onsen-gray-800;
    font-weight: normal;
    font-size: 42px;
  }
  
  .react-multi-carousel__arrow--right:hover {
    opacity: 0;
    background-color: transparent;
  }
  
  .react-multiple-carousel__arrow--left {
    left: -60px;
  }
  
  .react-multiple-carousel__arrow--right {
    right: -60px;
  }
  
  .react-multi-carousel-arrow:before {
    color: #000000; 
    font-size: 20px; 

  }
  
}


@include mobile {
  .top-gallery-wrapper {
    margin: 60px auto;
    .gallery-button-group {
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .top-gallery-wrapper {
      min-height: 145px;
      height: 150px;
      margin-bottom: 50px;
      padding-left: 12px;

    .carousel-item {
      display: flex;
      justify-content: flex-start;
    }
    .gallery-image {
      margin: 0;
      width: 100px;
    }

    .gallery-button-group {
      bottom: 0;
    }
  }
  
}
