.service-list-wrapper {
  text-align: center;
  padding: 40px;
  background-color: $onsen-background;
  margin: 40px 0;
}

.service-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}

.service-list-item {
  position: relative;
  width: 330px;
  height: auto;
  min-height: 325px;
  padding-bottom: 73px;
  background-color: $onsen-white;
  border: solid 1px $onsen-gray-border;
  border-radius: 5px;

  h3 {
    padding: 0 20px;
  }
}

.service-list-img {
  width: 330px;
  height: 165px;
  object-fit: cover;
  aspect-ratio: 2/1;
}

.service-list-icon-wrapper {
  position: relative;
  width: 63px;
  height: 63px;
  top: -32px;
  margin-bottom: -20px;
  background-color: $onsen-white;
  box-shadow: 
        -5px 0 8px rgba(0,0,0,0.1),  
        5px 0 8px rgba(0,0,0,0.1),   
        0 5px 8px rgba(0,0,0,0.1);
  z-index: 11;
}

.service-list-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 58px;
  border: 1px solid $onsen-black;
  background-color: $onsen-white;
  font-weight: 700;
}

@media screen and (max-width: $breakpoint-md-max) {
  .service-list-wrapper {
    height: auto;
    text-align: center;
    padding: 20px;
    background-color: $onsen-background;

    h1 {
      font-size: 18px;
    }
  }
  
  .service-list {
    display: block;
    margin: 30px 0;
  }
  
  .service-list-item {
    width: 100%;
    height: auto;
    background-color: $onsen-white;
    margin: 20px 0;
  }
  
  .service-list-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 2/1;
  }
  
  .service-list-icon-wrapper {
    position: relative;
    width: 63px;
    height: 63px;
    margin: 0 auto;
    top: -32px;
    margin-bottom: -20px;
    background-color: $onsen-white;
    box-shadow: 
          -5px 0 8px rgba(0,0,0,0.1),  
          5px 0 8px rgba(0,0,0,0.1),   
          0 5px 8px rgba(0,0,0,0.1);
  }
  
}