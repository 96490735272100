@import './breakpoints.scss';

.onsen-area-list-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    'hokkaido'
    'tohoku'
    'kanto'
    'chubu'
    'kansai'
    'chugoku'
    'shikoku'
    'kyushu';

  .item-wrapper {
    margin-bottom: 5px;
    width: 100%;
  }
  .item-wrapper:last-child {
    margin-bottom: 10px;
  }
  .region {
    border-bottom: 1px solid #333333;
    color: #2f2f2f;
    display: flex;
    font-size: 1.125rem;
    font-weight: bold;
    justify-content: space-between;
    margin: 0 0 20px;
    padding: 10px 10px 5px 0;
    position: relative;
  }
  .arrow-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .arrow {
    border: solid #333333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;

    &.arrow--up {
      transform: translate(0px, 3px) rotate(-135deg);
    }

    &.arrow--down {
      transform: translate(0px, -3px) rotate(45deg);
    }
  }
  .item-flex {
    display: block;
  }
  .prefecture {
    color: #333333;
    font-size: 0.9375rem;
    font-weight: bold;
    padding: 5px 15px 5px 0;
    margin: 0;
  }
  .link {
    color: #2f2f2f;
    display: block;
    font-size: 0.9375rem;
    font-weight: 400;
    margin: 0;
    padding-left: 5px;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .open {
    display: block;
  }
  .close {
    display: none;
  }
}

@include tablet {
  .onsen-area-list-container {
    grid-template-columns: repeat(3, 270px);
    grid-template-rows: repeat(3, 780px);
    grid-gap: 0 65px;
    grid-template-areas:
      'hokkaido tohoku kanto'
      'chubu chubu kansai'
      'chugoku shikoku kyushu';
    margin: 0 auto 3rem auto;
    width: fit-content;

    .arrow {
      display: none;
    }

    .item-flex {
      display: flex;
      flex-flow: column wrap;
      height: 100%;
    }
    .region {
      line-height: 1;
      margin-bottom: 10px;
      border-bottom: solid 1px #333333;
      white-space: nowrap;
      padding-bottom: 0.5rem;
      padding-left: 0;
    }
    .prefecture {
      margin-bottom: 0.25rem;
      margin-top: 0.5rem;
      letter-spacing: 0.66px;
      padding: 0;
    }
    .area {
      display: block;
    }
  }
}

:lang(en) {
  @include tablet {
    .onsen-area-list-container {
      .link {
        max-width: 220px;
      }
    }
  }
}
