.services-details {
  
  padding: 50px 40px;
}

.services-qna-item {
  border-top: solid 1px $onsen-gray-400;
  border-bottom: solid 1px $onsen-gray-400;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }
}
.services-qna-item + .services-qna-item {
  border-top: none;
}

.services-question {
  gap: 10px;
  margin-bottom: 30px;
}
.question-description {
  margin-top: 15px;
  display: flex;
  padding: 0 20px;
  margin-left: 100px;
}
.desc-text {
  padding: 0 20px;
  @include intro_p;
}
.desc-img {
  max-width: 180px;
  max-height: 120px;
  object-fit: cover;
}
.qna {
  display:flex;
}
.qna-icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: white;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  font-size: 40px;
  font-family: serif;
}
.question-text {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 5px 0 0 38px;
  min-height: 61px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  background-color: $onsen-gray-200;
  font-weight: bold;
  white-space: normal;
  text-align: left;
}

.question-text-underline {
  background-image: linear-gradient($onsen-gray-underline,$onsen-gray-underline);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% 10px;
  z-index: 1;
}

.question-text-icon {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $onsen-white;
  margin-right: 15px;
}
.question-text::before {
  content: "";         
  position: relative;
  top: 10px;            
  left: -18px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;     
  border-bottom: 10px solid transparent;
  border-right: 18px solid $onsen-gray-200;   
  transform: translateY(-50%);    
}
.answer-text {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 5px 20px 0 0;
  min-height: 61px;
  border-radius: 10px;
  background-color: $onsen-gray-200;
  font-weight: bold;
  white-space: normal;
  text-align: left;
  padding: 10px 10px 10px 25px;
}
.answer-arrow {
  content: "";     
  display: inline-block;    
  position: relative;
  top: 28px;    
  left: -20px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;     
  border-bottom: 10px solid transparent;
  border-left: 18px solid $onsen-gray-200;  
}
.answer-description {
  margin-top: 15px;
  width: 590px;
  display: flex;
}
.answer-warning {
  margin-top: 5px;
  font-size: 13px;
  line-height: 23px;
  color: $onsen-red;
}
.qna-link:hover {
  text-decoration: none;
}
.qna-button {
  position: relative;
  display: block;
  width: 300px;
  height: 58px;
  margin: 28px auto 0 auto;
  background-color: transparent;
  color: $onsen-black;
  border: solid 1px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  transition: ease .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;

  span {
    position: relative;
    z-index: 3;
    color: $onsen-black;
    transition: color .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }
}
.qna-button:hover {
  span {
    color: $onsen-white;
    text-decoration: none;
    z-index: 3;
    transition: color .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }
}
.bgleft:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background:#333;
  width: 100%;
  height: 100%;
  transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}

.bgleft:hover:before{
  transform-origin:left top;
  transform:scale(1, 1);
}

@media screen and (max-width: $breakpoint-md-max) {
  .services-details {
    min-height: 668px;
    width: 100%;
    border-top: none;
    border-bottom: solid 1px $onsen-gray-400;
    padding: 40px 10px;
  }
  .services-details + .services-details {
    border-top: none;
  }
  .services-question {
    gap: 10px;
    margin-bottom: 30px;
  }
  .question-description {
    margin-top: 15px;
    display: flex;
    width: 100%;
    padding: 0;
    flex-direction: column;
    margin-left: 0px;
  }
  .desc-text {
    width: 100%;
    padding: 5px;
    font-weight: normal;
    font-size: 15px;
  }
  .q-desc-text{
    padding-left: 60px;
    margin-bottom: 20px;
  }
  .desc-img-wrapper {
    width: 100%;
    margin-left: 0;
  }
  .desc-img {
    position: relative;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: auto;
    aspect-ratio: 345/160;
    object-fit: cover;
    box-sizing: border-box;
  }
  .qna {
    display:flex;
    align-items: center;
  }
  .qna-icon {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    color: white;
    width: 40px;
    height: 40px;
    padding-top: 2px;
    border-radius: 50%;
    font-size: 20px;
  }
  .question-text {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 7px 0 0 14px;
    min-height: 61px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    background-color: $onsen-gray-200;
    font-weight: bold;
    font-size: 16px;
    padding-right: 10px;
  }
  .question-text-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $onsen-white;
    margin-right: 5px;
  }
  .question-text::before {
    content: "";         
    position: relative;
    top: 8px;            
    left: -10px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;     
    border-bottom: 8px solid transparent;
    border-right: 12px solid $onsen-gray-200;   
    transform: translateY(-50%);    
  }
  .answer-text {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 7px 0 0 0;
    min-height: 61px;
    padding: 10px;
    border-radius: 10px;
    background-color: $onsen-gray-200;
    font-weight: bold;
    font-size: 16px;
  }
  .answer-arrow {
    content: "";     
    display: inline-block;    
    position: relative;
    top: 5px;
    left: -4px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;     
    border-bottom: 8px solid transparent;
    border-left: 12px solid $onsen-gray-200;  
  }
  .answer-description {
    margin-top: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 0px;
    font-size: 16px;
  }
  .a-desc-text{
    padding-right: 60px;
    margin-bottom: 20px;
  }
  .answer-warning {
    margin-top: 10px;
    font-size: 13px;
    line-height: 23px;
    color: $onsen-red;
  }
  .qna-button {
    display: block;
    width: 300px;
    height: 58px;
    margin: 20px auto 0 auto;
    background-color: transparent;
    color: $onsen-black;
  }
  .qna-button:hover {
    display: block;
    background-color: $onsen-black;
    color: $onsen-white;
  }
}